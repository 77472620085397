import Cart from '@app/lib/types/Cart';
import { getClient } from '@app/lib/shopify';
import { cartLinesUpdate } from '@app/lib/shopify/mutations/cart-lines-update';
import { ShopifyClientContext } from '@app/lib/shopify/client';
import { UserError } from 'shopify-storefront-api-typings';

export interface CartLineUpdateInput {
    id: string;
    quantity: number;
}

export interface CartLinesUpdateResponse {
    userErrors: UserError[];
    cart: Cart | null;
}

const updateLineItemQuantity = async (
    clientSettings: ShopifyClientContext,
    cartId: string,
    lineItemID: string,
    quantity: string
): Promise<CartLinesUpdateResponse> => {
    const lines: CartLineUpdateInput[] = [
        {
            id: lineItemID,
            quantity: parseInt(quantity),
        },
    ];
    try {
        const cartResponse = await getClient(clientSettings).request.send({
            query: cartLinesUpdate,
            variables: {
                country: clientSettings.shopifyMarketCountryCode,
                cartId,
                lines,
                next: { cache: 'nocache' },
            },
        });
        return cartResponse?.cartLinesUpdate || { userErrors: [], cart: null };
    } catch (error) {
        console.log(
            `Could not update line item quantity in cart: ${lineItemID}`,
            error
        );
        return {
            userErrors: [
                { message: 'An unexpected error occurred', field: [] },
            ],
            cart: null,
        };
    }
};

export default updateLineItemQuantity;
