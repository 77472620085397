'use client';

import cn from 'clsx';
import { Right } from 'mmds';
import React, { KeyboardEvent, useRef, useState, useEffect } from 'react';
import { useParams } from 'next/navigation';
import { hmacSignature } from '@arkadecx/arkade-cloud-functions-auth';
import { Language } from '@app/lib/store-context/Context';
import { getContext } from '@app/lib/store-context/get-context';
import s from './NewsletterSubscriptionForm.module.css';

const NewsletterSubscriptionForm = () => {
    const { lang } = useParams();
    const [mounted, setMounted] = useState<boolean>(false);
    const [message, setMessage] = useState<string | null>(null);
    const emailInputRef = useRef<HTMLInputElement>(null);
    const context = getContext(lang as Language);

    const onSubmit = () => {
        if (!emailInputRef.current?.value) return;
        const emailAddress = emailInputRef.current.value;
        const body = { emailAddress, region: context.countryCode };
        const route = '/api/services/newsletter-subscribe';
        const now = Date.now();

        try {
            hmacSignature(
                process.env.NEXT_PUBLIC_API_SECRET,
                now,
                route,
                body
            ).then((signature) => {
                fetch(route, {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-authentication': `${now}:${signature}`,
                    },
                    body: JSON.stringify(body),
                })
                    .then((response) => response.json())
                    .then((response) => {
                        if (response.error)
                            throw new Error(response.error.message);

                        setMessage(response.message);

                        if (
                            response.message ==
                            'Thank you for joining the MAAP mailing list.'
                        ) {
                            if (typeof window !== 'undefined') {
                                if ((window as any).dataLayer) {
                                    (window as any).dataLayer.push({
                                        event: 'gtm.newsletterSubscription',
                                    });
                                }
                                if ((window as any).ttq) {
                                    (window as any).track('Subscribe');
                                }
                                if ((window as any).pintrk) {
                                    (window as any).pintrk('track', 'Signup', {
                                        lead_type: 'Newsletter',
                                    });
                                }
                            }
                        }
                    })
                    .catch((error) => {
                        console.log('error', error);
                    });
            });
        } catch (error) {
            console.log('error', error);
            setMessage("Sorry we couldn't sign you up.");
        }
    };

    // Lastpass and other password managers inject HTML into the DOM, and that
    // causes a hydration miss-match / error simply because there is an input
    // field in this form.
    // Preventing the field from appearing until the component is mounted
    // stops this from happening.
    useEffect(() => {
        setMounted(true);
    }, []);

    return (
        <div className={s.newsletter}>
            <h4 className={cn('mmds-title-four')}>Roll with us</h4>
            <p className={cn('mmds-copy-two')}>Sign up to our Newsletter</p>
            <div className={s.newsletterInputContainer}>
                {mounted && (
                    <input
                        type="email"
                        id="email"
                        data-testid="newsletter_email_input"
                        className={cn(s.newsletterInput, 'mmds-component-one')}
                        ref={emailInputRef}
                        onKeyDown={(e) => {
                            if (
                                (e as KeyboardEvent<HTMLInputElement>).key ===
                                'Enter'
                            ) {
                                onSubmit();
                            }
                        }}
                        autoComplete={'email'}
                    />
                )}
                <button
                    type="button"
                    className={s.buttonIcon}
                    onClick={onSubmit}
                >
                    <Right />
                </button>
                {message && (
                    <div
                        className={cn(s.message, 'mmds-component-one-detail')}
                        data-testid="newsletter_validation_message"
                    >
                        {message}
                    </div>
                )}
            </div>
        </div>
    );
};

export default NewsletterSubscriptionForm;
