import { captureMessage } from '@sentry/nextjs';
import Cart from '@app/lib/types/Cart';
import { AttributeInput } from 'shopify-storefront-api-typings';
import { getClient } from '@app/lib/shopify';
import { cartAttributesUpdate } from '../mutations/cart-attributes-update';
import { ShopifyClientContext } from '@app/lib/shopify/client';

export default async (
    clientSettings: ShopifyClientContext,
    cartId: string,
    attributes: AttributeInput[],
    note?: string // Optional cart note if required.
): Promise<Cart | null> => {
    try {
        if (!cartId) {
            return null;
        }

        if (note) {
            attributes.push({ key: 'note', value: note });
        }

        const cartResponse = await getClient(clientSettings).request.send({
            query: cartAttributesUpdate,
            variables: {
                country: clientSettings.shopifyMarketCountryCode,
                cartId,
                attributes,
                next: { cache: 'nocache' },
            },
        });
        return cartResponse?.cartAttributesUpdate?.cart || null;
    } catch (e) {
        const message = `Could not add attributes to cart [${cartId}].`;
        captureMessage(message);
        console.warn(message, e);
    }
    return null;
};
