import { Suspense } from 'react';
import { ContentStructureGender } from '@app/lib/content-structure';
import { Language } from '@app/lib/store-context/Context';
import type { NavCards } from '../managed-navigation/managed-navigation';
import NavCard from '../nav-card/nav-card';

const FeatureGroupMap = {
    [`collections-${ContentStructureGender.MAN}`]: 'mensCollections',
    [`collections-${ContentStructureGender.WOMAN}`]: 'womensCollections',
    [`collaborations-${ContentStructureGender.MAN}`]: 'mensCollaborations',
    [`collaborations-${ContentStructureGender.WOMAN}`]: 'womensCollaborations',
    ['stores']: 'stores',
};

interface NavFeatureGroupProps {
    navCards: NavCards | null;
    groupID: string;
    lang: Language;
}

const NavFeatureGroup = ({ navCards, groupID, lang }: NavFeatureGroupProps) => {
    if (!navCards) return null;

    const key = FeatureGroupMap[groupID];
    const cards = navCards[key];

    if (!cards) return null;

    return (
        <Suspense fallback={<div>Loading features...</div>}>
            {cards.map((navCard, index) => (
                <NavCard
                    key={index}
                    ctaText={navCard.ctaText}
                    ctaLink={navCard.ctaLink}
                    image={navCard.image}
                    lang={lang}
                />
            ))}
        </Suspense>
    );
};

export default NavFeatureGroup;
