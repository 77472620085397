import { gql } from 'graphql-request';
import { CART_FIELDS } from '@app/lib/shopify/queries/fragments/cart-fields';

export const cartLinesUpdate = gql`
    mutation cartLinesUpdate(
        $country: CountryCode
        $cartId: ID!
        $lines: [CartLineUpdateInput!]!
    ) @inContext(country: $country) {
        cartLinesUpdate(cartId: $cartId, lines: $lines) {
            userErrors {
                message
                field
            }
            cart {
                ...cartFields
            }
        }
    }
    ${CART_FIELDS}
`;
