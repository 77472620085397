import { gql } from 'graphql-request';
import { CART_FIELDS } from './fragments/cart-fields';

export const cart = gql`
    query cart($country: CountryCode, $cartId: ID!)
    @inContext(country: $country) {
        cart(id: $cartId) {
            ...cartFields
        }
    }
    ${CART_FIELDS}
`;
