import formatComponentName from '@app/components/ui/utils/format-component-name';

export default function setIsDismissedCookieValue(componentName: string) {
    if (typeof document === 'undefined') return null;
    const EXPIRATION_DAYS = 28;
    const expires = `; expires=${new Date(
        Date.now() + EXPIRATION_DAYS * 24 * 60 * 60 * 1000 // That's a lot of seconds.
    ).toUTCString()}`;
    document.cookie =
        formatComponentName(componentName) + '=' + true + expires + '; path=/';
}
