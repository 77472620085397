'use client';

import withDismissible from '@app/components/ui/dismissible';
import mapCountryNameToRegionCode from '@app/lib/vercel/map-country-name-to-region-code';
import cn from 'classnames';
import { Button } from 'mmds';
import { useRouter } from 'next/navigation';
import s from './dismissible-country-notice-ribbon.module.css';

interface CountryNoticeRibbonProps {
    contextCountryCode: string;
    clientCountryCode: string;
    setIsCountryNoticeDismissed: (value: boolean) => void;
    /**
     * Is currently dismissed
     */
    dismissed: boolean;
    /**
     * Dismiss function from useDismissible
     */
    dismiss: (name: string) => void;

    /**
     * Receives a value while the component is transitioning in or out
     */
    transition: null | 'in' | 'out';
}

let name = 'Country Notice Ribbon';

const DismissibleCountryNoticeRibbon = ({
    contextCountryCode,
    clientCountryCode,
    dismiss,
    dismissed,
    transition,
    setIsCountryNoticeDismissed,
    ...props
}: CountryNoticeRibbonProps) => {
    const regionMappedCountryCode =
        mapCountryNameToRegionCode(clientCountryCode);
    const countryCodeTitleMap = {
        AU: 'Australia/International',
        US: 'United States',
        CA: 'Canada',
        UK: 'United Kingdom',
        GB: 'United Kingdom',
        KR: 'South Korea',
        EU: 'European Union',
        SG: 'Singapore',
        TW: 'Taiwan Region',
        HK: 'Hong Kong',
    };

    let regionNames = new Intl.DisplayNames(['en'], { type: 'region' });
    const countryName = regionNames.of(clientCountryCode);

    const router = useRouter();

    if (
        !countryCodeTitleMap[regionMappedCountryCode] ||
        !countryCodeTitleMap[regionMappedCountryCode]
    ) {
        return null;
    }

    const title = `Delivery to ${countryName || countryName}?`;

    const dismissalHandler = () => {
        setIsCountryNoticeDismissed(true);
        dismiss(name);
    };

    const handleRedirect = () => {
        setIsCountryNoticeDismissed(true);
        if (regionMappedCountryCode === 'KR') {
            router.push('https://maap.kr');
            return;
        }
        router.push(`/${regionMappedCountryCode.toLowerCase()}`);
    };

    return (
        <div
            role="navigation"
            className={cn(
                s.root,
                { [s.hidden]: dismissed },
                { [s.in]: transition == 'in' },
                { [s.out]: transition == 'out' }
            )}
            data-testid={`ribbon-${name}`}
            {...props}
        >
            <div className={cn(s.column, s.leftColumn)}></div>
            <div className={s.column}>
                <div className={cn(s.content, 'mmds-component-one-detail')}>
                    {title && (
                        <div className={s.title}>
                            <span>{title}</span>
                        </div>
                    )}
                    <div className={s.description}>
                        {`You're currently viewing our ${countryCodeTitleMap[contextCountryCode]} store, 
                        would you prefer to shop on our ${countryCodeTitleMap[regionMappedCountryCode]} site?`}
                    </div>
                </div>
            </div>
            <div className={cn(s.column, s.end, s.buttons)}>
                <Button
                    icon="globe"
                    variant={'secondary'}
                    onClick={handleRedirect}
                >
                    {`Shop ${
                        clientCountryCode === 'AU'
                            ? 'AU/INTL'
                            : regionMappedCountryCode
                    } Store`}
                </Button>
                <Button
                    icon="close"
                    variant={'ghost'}
                    onClick={dismissalHandler}
                    theme="light"
                >
                    Continue Shopping
                </Button>
                <Button
                    icon="close"
                    isIconNoMargin={true}
                    variant={'secondary'}
                    onClick={dismissalHandler}
                ></Button>
            </div>
        </div>
    );
};

export default withDismissible(DismissibleCountryNoticeRibbon, name);
