export default function getPseudoLang() {
    if (typeof document === 'undefined') return null;
    const cookie = document.cookie
        .split('; ')
        .find((cookie) => cookie.startsWith('_pseudo_lang'));
    if (!cookie) {
        return null;
    }
    return cookie.replace(`${cookie.split('=')[0]}=`, '');
}
