import React, { useEffect, useState } from 'react';
import { Button, Left } from 'mmds';
import { useParams, useRouter } from 'next/navigation';
import { getContext } from '@app/lib/store-context/get-context';
import getCurrencySymbol from '@app/lib/helpers/get-currency-symbol';
import { Language } from '@app/lib/store-context/Context';
import {
    ContentStructure,
    ContentStructureNavItem,
    useContentStructure,
} from '@app/lib/content-structure';
import useUI from '@app/components/ui/state';
import useDrawerDirection from '@app/lib/hooks/use-drawer-direction';
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerPanel,
} from '@app/components/ui/drawer/Drawer';
import CatalogueNavItems from '@app/components/nav-sidebar/catalogue-nav-items';
import { HeaderNavCallbacks } from '@app/components/header-nav/types';
import localisePath from '@app/lib/helpers/localise-path';
import useLoopReturns from '@app/lib/loop-returns/state';
import NavFeatureGroup from '@app/components/nav-feature-group/nav-feature-group';
import type { NavCards } from '@app/components/managed-navigation/managed-navigation';
import cn from 'clsx';
import s from './nav-sidebar-mobile.module.css';
import ucFirst from '@app/lib/string/uc-first';
import StoryFeatureGroup from '../nav-feature-group/story-feature-group';
import capitalize from 'lodash.capitalize';
import lowerCase from 'lodash.lowercase';

const NavSidebarMobile = ({
    contentStructure,
    navCards,
    storiesCards,
    activeNavItem = null,
    callbacks,
}: {
    callbacks: HeaderNavCallbacks;
    navCards: NavCards | null;
    storiesCards: NavCards | null;
    activeNavItem: ContentStructureNavItem | null;
    contentStructure: ContentStructure;
}) => {
    const { openUIKey, toggleUI } = useUI((state) => {
        return {
            openUIKey: state.openUIKey,
            toggleUI: state.toggleUI,
        };
    });
    const drawerDirection = useDrawerDirection('left', 'left');
    const isDrawerOpen = openUIKey === 'main-menu-mobile';
    const { lang } = useParams();
    const { isCreditShoppingActive } = useLoopReturns((state) => ({
        isCreditShoppingActive: state.isCreditShoppingActive,
    }));
    const context = getContext(lang as Language);
    const { currencyCode } = context;
    const { structure, getPathsForNode, getBranchLengthForNode } =
        useContentStructure(contentStructure);
    const [activeNodePath, setActiveNodePath] = useState<
        ContentStructureNavItem[] | null
    >(null);
    const [selectedNode, setSelectedNode] =
        useState<null | ContentStructureNavItem>();
    const [items, setItems] = useState<ContentStructure>(structure);
    const [openCluster, setOpenCluster] = useState<null | string>(null);
    const [activeFeaturePanel, setActiveFeaturePanel] = useState<null | string>(
        null
    );
    const router = useRouter();

    useEffect(() => {
        if (activeNavItem) {
            const preselectedItemPath = getPathsForNode(activeNavItem);
            if (preselectedItemPath.length === 1) {
                setSelectedNode(preselectedItemPath[0]);
            } else {
                preselectedItemPath.forEach((item: ContentStructureNavItem) => {
                    const itemBranchLength =
                        getBranchLengthForNode(activeNavItem);
                    const pathsForItem = getPathsForNode(item);
                    const currentPosition = pathsForItem.length;
                    const position: number = itemBranchLength - currentPosition;
                    if (position > 1) {
                        setSelectedNode(item);
                    }
                    if (position === 1) {
                        setOpenCluster(item.label);
                    }
                });
            }
        }
    }, [activeNavItem]);

    const reset = () => {
        if (activeFeaturePanel) {
            setActiveFeaturePanel(null);
        } else {
            setSelectedNode(null);
            setActiveNodePath(null);
            setItems(structure);
            setOpenCluster(null);
        }
    };

    const closeSidebar = () => {
        toggleUI('main-menu-mobile');
        router.push(localisePath('/', lang as Language) as string);
    };

    useEffect(() => {
        if (selectedNode) {
            const activeNodePath: ContentStructureNavItem[] =
                getPathsForNode(selectedNode);
            setActiveNodePath(activeNodePath);
            if (selectedNode.children) {
                setItems(selectedNode.children);
            }
        }
    }, [selectedNode]);

    const storiesCardCategories =
        storiesCards?.stories?.map((story) =>
            capitalize(lowerCase(story.category))
        ) || [];

    const onSetOpenCluster = (cluster: string) => {
        if (['Collaborations', 'Collections'].includes(cluster)) {
            const panelId = `${cluster.toLowerCase()}-${selectedNode?.gender}`;
            if (activeFeaturePanel === panelId) setActiveFeaturePanel(null);
            else {
                setActiveFeaturePanel(panelId);
                setOpenCluster(null);
            }
            return;
        }

        if (cluster === 'Stores (LaB)' || cluster === 'Stores') {
            const panelId = 'stores';
            if (activeFeaturePanel === panelId) setActiveFeaturePanel(null);
            else {
                setActiveFeaturePanel(panelId);
                setOpenCluster(null);
            }
            return;
        }

        if (storiesCardCategories.includes(cluster)) {
            //  const panelId = `${cluster.toLowerCase()}-${activeNavItem?.gender}`;
            const panelId = `${cluster.toLowerCase()}-unisex`;
            if (activeFeaturePanel === panelId) setActiveFeaturePanel(null);
            else {
                setActiveFeaturePanel(panelId);
                setOpenCluster(null);
            }
            return;
        }

        setOpenCluster(cluster);
        setActiveFeaturePanel(null);
        return;
    };
    const currencySymbol = getCurrencySymbol(lang as Language, currencyCode);
    const langAsCountry = (language: Language) => {
        if (language) {
            return language.split('-')[1];
        }
        return 'AU'; // Its really only the framework level 404 page that should encounter this.
    };
    let panelTitle = selectedNode && selectedNode.label;

    const isStoriesFeaturePanel = storiesCardCategories
        .map((item) => item.toLowerCase())
        .includes(activeFeaturePanel?.replace('-unisex', ''));

    if (activeFeaturePanel && selectedNode?.gender) {
        panelTitle = `${ucFirst(selectedNode.gender)}.${ucFirst(
            activeFeaturePanel.split('-')[0]
        )}`;
    }

    if (isStoriesFeaturePanel && panelTitle) {
        panelTitle = panelTitle.split('.')[1];
    }

    const filterLabel = items?.filter(
        (item) => item?.hrefLink === '/labs' || item?.hrefLink === '/stories'
    )[0]?.label;

    const filteredLabel =
        activeFeaturePanel === 'stories' ? 'Stories' : filterLabel;

    return (
        <Drawer
            open={isDrawerOpen}
            onOpenChange={() => {
                toggleUI('main-menu-mobile');
            }}
        >
            <DrawerPanel inFrom={drawerDirection} variant="catalogue-nav">
                <DrawerHeader
                    className="mmds-copy-three"
                    closeButtonVariant="text"
                >
                    {!selectedNode &&
                        activeFeaturePanel !== 'stores' &&
                        activeFeaturePanel !== 'stories' && (
                            <Button
                                label="Home"
                                variant="text"
                                padding="zero"
                                onClick={closeSidebar}
                            />
                        )}
                    {selectedNode && (
                        <a onClick={reset}>
                            <Left />
                        </a>
                    )}
                    {(activeFeaturePanel === 'stores' ||
                        activeFeaturePanel === 'stories') && (
                        <a onClick={reset}>
                            <Left />
                        </a>
                    )}
                    <div className={cn(s.title, 'mmds-component-one-detail')}>
                        {activeFeaturePanel === 'stores' ||
                        activeFeaturePanel === 'stories'
                            ? filteredLabel
                            : panelTitle}
                    </div>
                </DrawerHeader>
                {!activeFeaturePanel && (
                    <DrawerBody>
                        <CatalogueNavItems
                            contentStructure={contentStructure}
                            items={items}
                            callbacks={callbacks}
                            activeNavItem={activeNavItem}
                            activeNodePath={activeNodePath}
                            openCluster={openCluster}
                            setOpenCluster={onSetOpenCluster}
                            setSelectedNode={setSelectedNode}
                            selectedNode={selectedNode}
                            lang={lang as Language}
                            activeFeaturePanel={activeFeaturePanel}
                        />
                    </DrawerBody>
                )}
                {activeFeaturePanel && !isStoriesFeaturePanel && (
                    <DrawerBody>
                        <NavFeatureGroup
                            groupID={activeFeaturePanel}
                            navCards={navCards}
                            lang={lang as Language}
                        />
                    </DrawerBody>
                )}
                {activeFeaturePanel &&
                    storiesCards &&
                    isStoriesFeaturePanel && (
                        <DrawerBody className={s.storiesFeatureDrawBody}>
                            <StoryFeatureGroup
                                groupID={activeFeaturePanel}
                                storiesCards={storiesCards}
                                lang={lang as Language}
                                compact
                            />
                        </DrawerBody>
                    )}
                {!activeFeaturePanel && !isCreditShoppingActive && (
                    <DrawerFooter className="mmds-copy-three">
                        <div className={s.footer}>
                            <button onClick={callbacks.toggleLanguageSwitch}>
                                <span>
                                    {langAsCountry(lang as Language)} /{' '}
                                    {currencyCode} {currencySymbol}
                                </span>
                            </button>
                            |
                            <button onClick={callbacks.toggleSupport}>
                                Support
                            </button>
                        </div>
                    </DrawerFooter>
                )}
            </DrawerPanel>
        </Drawer>
    );
};

export default NavSidebarMobile;
