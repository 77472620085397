import React from 'react';
import cn from 'clsx';
import s from '../cart-complete-bundle-notice/cart-complete-bundle-notice.module.css';

const CartGiftNotice = ({
    gift,
    variant = 'sidebar',
}: {
    gift: any; //TODO
    variant?: 'sidebar' | 'page';
}) => {
    if (!gift) return null;
    const title = gift.giftTitle;
    const description = gift.giftDescription;

    return (
        <div
            className={cn(s.root, {
                [s.sidebar]: variant === 'sidebar',
                [s.page]: variant === 'page',
            })}
        >
            <div className={s.text}>
                <h3 className={cn(s.title, 'mmds-copy-three')}>{title}</h3>
                <p className="mmds-copy-three-serif">{description}</p>
            </div>
        </div>
    );
};

export default CartGiftNotice;
