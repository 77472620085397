const EU_COUNTRIES = {
    austria: 'AT',
    belgium: 'BE',
    bulgaria: 'BG',
    croatia: 'HR',
    czechRepublic: 'CZ',
    denmark: 'DK',
    estonia: 'EE',
    finland: 'FI',
    france: 'FR',
    germany: 'DE',
    greece: 'GR',
    hongKong: 'HK',
    hungary: 'HU',
    ireland: 'IE',
    italy: 'IT',
    latvia: 'LV',
    lithuania: 'LT',
    luxembourg: 'LU',
    malta: 'MT',
    netherlands: 'NL',
    poland: 'PL',
    portugal: 'PT',
    republicOfCyprus: 'CY',
    romania: 'RO',
    slovakia: 'SK',
    slovenia: 'SI',
    spain: 'ES',
    sweden: 'SE',
};

const mapCountryNameToRegionCode = (userCountry: string) => {
    const euCountryCodes = Object.values(EU_COUNTRIES);

    if (euCountryCodes.indexOf(userCountry) > -1) {
        return 'EU';
    }

    switch (userCountry) {
        case 'UK':
        case 'GB':
            return 'UK';
        case 'SG':
        case 'US':
        case 'KR':
            return userCountry;
        default:
            return 'AU';
    }
};

export default mapCountryNameToRegionCode;
