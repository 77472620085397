'use client';
import React, { useEffect, useState } from 'react';
import { useParams } from 'next/navigation';
import brandConfig from '@config/brandConfig';
import s from './support-button.module.css';
import cn from 'clsx';

type ZeState = 'open' | 'close'

export default function SupportButton() {
    const [loading, setLoading] = useState(true)
    const [zeState, setZeState] = useState<ZeState>('close')
    const { lang } = useParams();
   
    const triggerWidget = (state: ZeState) => {
        const locale = (lang as string).split("-")[0];
        (window as any).zE('messenger:set', 'locale', locale);
        (window as any).zE('messenger', state);
    };

    const onTriggerWidget = () => {
        const state = zeState === 'open' ? 'close' : 'open';
        triggerWidget(state)
        setZeState(state);
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            (window as any).zE('messenger', zeState);
            (window as any).zE('messenger:on', 'close', () => setZeState("close"))
            setLoading(false)
        },300)

        return () => clearTimeout(timer)
    },[]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <script
                id="ze-snippet"
                src={`https://static.zdassets.com/ekr/snippet.js?key=${brandConfig.zendeskKey}`}
                async
            />
            {!loading && (
                <div id="customLauncher" className={cn(s.root, "mmds-component-one")} onClick={onTriggerWidget}>
                    <span>Live Chat</span>
                    <div className={s.icon}></div>
                </div>
            )}
        </>
    );
}
