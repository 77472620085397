'use client';

import useWishlist from '@app/lib/wishlist/state';
import { useEffect } from 'react';
const WishlistInitialiser = () => {
    const { load, items } = useWishlist();

    useEffect(() => {
        // If there's items, the local store has been loaded by Zustand.
        // otherwise, there might be a remote wishlist to load up.
        if (!items.length) {
            load();
        }
    }, [load, items]);

    return null;
};

export default WishlistInitialiser;
