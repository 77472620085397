import CartLineItemEdge from '@app/lib/types/CartLineItemEdge';

export default function getUpsellMessage(lineItems: CartLineItemEdge[]) {
    const allTypes: string[] = [];
    const hasBundleId = (item: CartLineItemEdge) =>
        item.node.attributes.some(
            (attribute) => attribute.key === '_bundle_id'
        );

    const itemWithBundleId = lineItems.some(hasBundleId);

    lineItems.forEach(function (edge) {
        if (itemWithBundleId) {
            return;
        }

        const type = edge.node.merchandise.product?.productType;

        if (
            edge.node.merchandise.compareAtPrice?.amount &&
            edge.node.merchandise.price.amount <
                edge.node.merchandise.compareAtPrice.amount
        ) {
            return;
        }

        if (!allTypes.includes(type)) {
            allTypes.push(type);
        }
    });

    // The acceptable types for Bibs, Jerseys, and Socks
    const bibTypes =
        "Bib, Bibs, Bib Short, Bib Shorts, Long Bib, Women's Long Bib, Bib Shorts, Bib Liners, Women's Bib, Women's Bibs, Women's Bib Short, Women's Bib Shorts, Women's Bib Liners".split(
            ', '
        );

    const jerseyTypes =
        "Jersey, Long Sleeve Jersey, Jerseys, Women's Jersey, Women's Long Sleeve Jerseys, Women's Jerseys".split(
            ', '
        );

    const sockTypes = 'Socks'.split(', ');

    // Find out which of these types are currently in the cart
    const hasBib = allTypes.some((type) => bibTypes.includes(type));
    const hasJersey = allTypes.some((type) => jerseyTypes.includes(type));
    const hasSocks = allTypes.some((type) => sockTypes.includes(type));

    // Determine the message to display to customer
    let message: string | null = null;
    if (hasBib && hasJersey && hasSocks) {
        message = null;
    } else if (hasBib && hasJersey) {
        message =
            'Save 15% by completing our MAAP Kits bundle. Simply add your choice of full price Socks.';
    } else if (hasSocks && hasJersey) {
        message =
            'Save 15% by completing our MAAP Kits bundle. Simply add your choice of full price Bib Shorts or Tights.';
    } else if (hasSocks && hasBib) {
        message =
            'Save 15% by completing our MAAP Kits bundle. Simply add your choice of full price Jersey.';
    } else if (hasBib) {
        message =
            'Save 15% by completing our MAAP Kits bundle. Simply add your choice of full price Jersey and Socks.';
    } else if (hasJersey) {
        message =
            'Save 15% by completing our MAAP Kits bundle. Simply add your choice of full price Bib Shorts or Tights and Socks.';
    } else if (hasSocks) {
        message =
            'Save 15% by completing our MAAP Kits bundle. Simply add your choice of full price Jersey and Bib Shorts or Tights.';
    }

    return message;
}
