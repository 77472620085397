import { useEffect, useState } from 'react';
import { Icon } from 'mmds';
import { Language } from '@app/lib/store-context/Context';
import type { NavCards } from '../managed-navigation/managed-navigation';
import { getClient } from '@app/lib/shopify';
import { articlesQueryLite } from '@lib/gql/article';
import { Article } from 'shopify-storefront-api-typings';
import BlogArticleCard from '@components/BlogArticleCard';
import Linker from '@app/components/linker/linker';
import useUI from '@app/components/ui/state';
import lowerCase from 'lodash.lowercase';
import kebabCase from 'lodash.kebabcase';
import cn from 'clsx';
import s from './nav-feature-group.module.css';

interface StoryFeatureGroupProps {
    storiesCards: NavCards | null;
    groupID: string;
    lang: Language;
    compact?: boolean;
}

const StoryFeatureGroup = ({
    storiesCards,
    groupID,
    lang,
    compact = false,
}: StoryFeatureGroupProps) => {
    const [articles, setArticles] = useState<Article[]>([]);
    const { toggleUI } = useUI();
    const activeCategory = groupID.split('-')[0];
    const storyCard = storiesCards?.stories.find(({ category }) =>
        activeCategory.includes(lowerCase(category))
    );
    useEffect(() => {
        if (storyCard) {
            setArticles([]);
            const query = activeCategory
                ? `tag:\\"collection:${activeCategory}\\"`
                : 'tag:*';
            getClient(null)
                .request.send({
                    query: articlesQueryLite(5, query),
                })
                .then((response) => {
                    setArticles(response?.blogByHandle?.articles?.nodes);
                });
        }
    }, [activeCategory]);
    return (
        <>
            <div
                className={cn(s.allCategory, 'mmds-component-one-link', {
                    [s.compact]: compact,
                })}
                onClick={() => toggleUI(null)}
            >
                <Linker
                    title={`All articles in ${activeCategory} category`}
                    lang={lang}
                    href={`/stories/${kebabCase(activeCategory)}`}
                >
                    All this category
                </Linker>
                <Icon icon="northeast" />
            </div>
            <div className={s.wrapper}>
                {articles.map((article, index) => {
                    const { handle, title, image, content, publishedAt } =
                        article;

                    return (
                        <div
                            key={index}
                            className={s.container}
                            onClick={() => toggleUI(null)}
                        >
                            <BlogArticleCard
                                key={index}
                                handle={handle}
                                category={activeCategory}
                                title={title}
                                imageUrl={image?.transformedSrc}
                                body={content}
                                published_at={publishedAt}
                                lang={lang}
                            />
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default StoryFeatureGroup;
