'use client';

import useCart from '@app/[lang]/cart/state';
import { getShopifyClientSettings } from '@app/lib/shopify/get-client-settings';
import { useParams, useSearchParams } from 'next/navigation';
import { Language } from '@app/lib/store-context/Context';
import { useEffect, useState } from 'react';
import { getClient } from '@app/lib/shopify';
import Cart from '@app/lib/types/Cart';
import { cart as cartQuery } from '@app/lib/shopify/queries/cart';
import getCartId from '@app/lib/shopify/checkout/get-cart-id';
import deleteCartId from '@app/lib/shopify/checkout/delete-cart-id';
import { cartContainsGifts } from '@app/lib/helpers/cart-contains-gifts';
import { captureException } from '@sentry/nextjs';
import { cartDiscountCodesUpdate } from '@app/lib/shopify/mutations/cart-discount-codes-update';

interface CartQueryResponse {
    cart: Cart | null;
}

const CartInitialiser = ({ gift }: { gift: any }) => {
    const seachParams = useSearchParams()
    const discountCode = seachParams.get("discount_code")

    const { lang } = useParams();
    const { initialised, setCart, cart } = useCart((state) => ({
        initialised: state.initialised,
        setCart: state.setCart,
        cart: state.cart
    }));

    const clientSettings = getShopifyClientSettings(lang as Language);

    function cartGiftStateInvalid(cart: Cart, gift: any): boolean {
        const cartContainsGift = cartContainsGifts(cart);
        return cartContainsGift && !gift;
    }

    useEffect(() => {
        const cartID = getCartId(lang as Language);
        if (!cartID || !lang) {
            return;
        }
        const fetchCart = async () => {
            try {
                const response: CartQueryResponse = await getClient(
                    clientSettings
                ).request.send({
                    query: cartQuery,
                    variables: {
                        cartId: cartID,
                    },
                });

                const fetchedCart = response.cart;
                if (fetchedCart === null) {
                    deleteCartId(lang as Language);
                } else if (cartGiftStateInvalid(fetchedCart, gift)) {
                    deleteCartId(lang as Language);
                } else {
                    setCart(fetchedCart);
                }
            } catch (error) {
                captureException(error);
            }
        };
        fetchCart().then((r) => r);
    }, [lang]);

    useEffect(() => {
        if (initialised) {
            const cartID = getCartId(lang as Language);
            const storedDiscountCode = sessionStorage.getItem('discountCode')
            if (!discountCode && !storedDiscountCode) return

            const appliedDiscount = !discountCode 
                ? storedDiscountCode 
                : discountCode !== storedDiscountCode ? discountCode : storedDiscountCode

            getClient(
                clientSettings
            ).request.send({
                query: cartDiscountCodesUpdate,
                variables: {
                    cartId: cartID,
                    discountCodes: appliedDiscount
                },
            }).then(response => {
                if (response.cartDiscountCodesUpdate) {
                    setCart(response.cartDiscountCodesUpdate.cart)
                }
            })
        }
    }, [initialised, discountCode])

    useEffect(() => {
        if (discountCode) {
            sessionStorage.setItem('discountCode', discountCode);
        }
    },[discountCode])
    
    return null;
};

export default CartInitialiser;
