'use client';
import { useState, useEffect } from 'react';

const ProductZendeskWidgetModifier = ({ id }: { id?: string }) => {
    const [hidden, setHidden] = useState(true);
    useEffect(() => {
        if ((window as any) === 'undefined') return;
        window.addEventListener('scroll', 
            () => {
                setHidden(window.scrollY < 110);
                
                if(window.scrollY < 110){
                    (window as any).zE('messenger', 'close');
                }
            },
            { passive: true }
        );
        return window.removeEventListener('scroll', () =>
            setHidden(window.scrollY < 110)
        );
    }, []);
    return (
        <style>{`
        #${id || "launcher"} {
            right: var(--spacing-size-three) !important;
            bottom: var(--spacing-size-three) !important;
            ${hidden ? 'display: none;' : ''}
        }
    `}</style>
    );
};

export default ProductZendeskWidgetModifier;
