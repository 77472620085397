import cn from 'clsx';
import { ContentStructureNavItem } from '@app/lib/content-structure';
import { slugify } from 'mmds';
import { HeaderNavCallbacks } from '@app/components/header-nav/types';
import s from './header-nav-items.module.css';
import { Language } from '@app/lib/store-context/Context';

const HeaderNavItems = ({
    items,
    activeItem,
    callbacks,
    compact,
    lang,
}: {
    items: ContentStructureNavItem[];
    activeItem: ContentStructureNavItem | null;
    callbacks: HeaderNavCallbacks;
    compact?: boolean;
    lang: Language;
}) => {
    return (
        <div className={cn(s.root)}>
            {items.map((item: ContentStructureNavItem) => {
                const itemClassNames = cn(s.item, {
                    [s.active]: item.label === activeItem?.label,
                    [s.compact]: compact,
                });
                return (
                    <button
                        className={itemClassNames}
                        key={slugify(item.label)}
                        data-testid={`dn-item-${slugify(item.label)}`}
                        onClick={() => callbacks.toggleItem(item)}
                        lang={lang}
                    >
                        {item.label}
                    </button>
                );
            })}
        </div>
    );
};

export default HeaderNavItems;
