'use client';
import { contentStructureFooterNavItem, FooterNavProps } from './types';
import NewsletterSubscriptionForm from './NewsletterSubscriptionForm';
import { useParams } from 'next/navigation';
import cn from 'clsx';
import s from './FooterNav.module.css';
import Linker from '../linker/linker';
import { Language } from '@app/lib/store-context/Context';

export const FooterNavDesktopVariant = ({ items }: FooterNavProps) => {
    const { lang } = useParams();
    const openConsentWidget = () => {
        return (window as any).Osano.cm.showDrawer(
            'osano-cm-dom-info-dialog-open'
        );
    };
    return (
        <div className={cn(s.root, s.desktop)}>
            {items.map((item: contentStructureFooterNavItem) => {
                return (
                    <div
                        key={`${item.hrefLink}-${item.label}`}
                        className={s.primaryItem}
                    >
                        <div className={cn(s.primaryLabel, 'mmds-copy-three')}>
                            {item.label}
                        </div>
                        <div className={s.primaryChildren}>
                            {item?.children?.map((item) => {
                                if (item.label === 'Cookie Preferences') {
                                    return (
                                        <a
                                            key={`${item.hrefLink}-${item.label}`}
                                            // href={item.hrefLink}
                                            onClick={openConsentWidget}
                                        >
                                            <div
                                                className={cn(
                                                    'mmds-component-one'
                                                )}
                                            >
                                                {item.label}
                                            </div>
                                        </a>
                                    );
                                }

                                if (!item.hrefLink) return null;
                                return (
                                    <Linker
                                        key={`${item.hrefLink}-${item.label}`}
                                        href={item.hrefLink}
                                        title={item.label}
                                        target={item.target}
                                        lang={lang as Language}
                                    >
                                        <div
                                            className={cn('mmds-component-one')}
                                        >
                                            {item.label}
                                        </div>
                                    </Linker>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
            <NewsletterSubscriptionForm />
        </div>
    );
};

export default FooterNavDesktopVariant;
