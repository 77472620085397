import React from 'react';
import CartLineItemConnection from '@app/lib/types/CartLineItemConnection';
import getUpsellMessage from './legacy-upsell-calculator';
import cn from 'clsx';
import s from './cart-complete-bundle-notice.module.css';

const CartCompleteBundleNotice = ({
    lineItemConnection,
    variant = 'sidebar',
}: {
    lineItemConnection: CartLineItemConnection;
    variant?: 'sidebar' | 'page';
}) => {
    if (!lineItemConnection) return null;
    const upsellText = getUpsellMessage(lineItemConnection.edges);
    if (!upsellText) return null;

    return (
        <div
            className={cn(s.root, {
                [s.sidebar]: variant === 'sidebar',
                [s.page]: variant === 'page',
            })}
        >
            <div className={s.text}>
                <h3 className={cn(s.title, 'mmds-copy-three')}>Bundles</h3>
                <p className="mmds-copy-three-serif">{upsellText}</p>
            </div>
        </div>
    );
};

export default CartCompleteBundleNotice;
