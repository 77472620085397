'use client';

import React, { useState, KeyboardEvent } from 'react';
import useUI from '@app/components/ui/state';
import { Drawer, DrawerPanel } from '@app/components/ui/drawer/Drawer';
import { useParams, useRouter } from 'next/navigation';
import { Language } from '@app/lib/store-context/Context';
import { getContext } from '@app/lib/store-context/get-context';
import throttle from '@app/lib/helpers/throttle';
import { Button, Right } from 'mmds';
import cn from 'clsx';
import s from './search-panel.module.css';

const nostoDebouncedSearchTracking = throttle((value) => {
    typeof (window as any).nostojs === 'function' &&
        (window as any).nostojs((api) => {
            api.defaultSession()
                .viewSearch(value)
                .setPlacements(['search-related']);
            const session = api.defaultSession();
            session.update();
        });
}, 500);

export const SearchPanel = () => {
    const { lang } = useParams();
    const [value, setValue] = useState<string>('');
    const { push } = useRouter();
    const { openUIKey, toggleUI } = useUI();
    const isDrawerOpen = openUIKey === 'search-panel';
    const { countryCode } = getContext(lang as Language);
    return (
        <Drawer
            open={isDrawerOpen}
            onOpenChange={() => toggleUI('search-panel')}
        >
            <DrawerPanel inFrom="top" variant="search-panel">
                <div className={s.root}>
                    <div className={s.close}>
                        <Button
                            icon="close"
                            variant="text"
                            onClick={() => toggleUI('search-panel')}
                            data-testid="control-search-panel-close"
                        >
                            <span className={s.closeText}>Close</span>
                        </Button>
                    </div>
                    <div className={s.inputContainer}>
                        <input
                            type="text"
                            className={cn(s.giantInput)}
                            placeholder="Type something"
                            onInput={(e) =>
                                setValue(
                                    (e.target as HTMLTextAreaElement)?.value
                                )
                            }
                            onKeyDown={(e) => {
                                if (
                                    (e as KeyboardEvent<HTMLInputElement>)
                                        .key === 'Enter'
                                ) {
                                    nostoDebouncedSearchTracking(value);
                                    push(
                                        `/${countryCode.toLowerCase()}/search/${value}`
                                    );
                                    toggleUI('search-panel');
                                }
                            }}
                            data-testid="control-search-input"
                            autoFocus
                        />
                        {!!value.length && (
                            <button
                                onClick={() => {
                                    nostoDebouncedSearchTracking(value);
                                    push(
                                        `/${countryCode.toLowerCase()}/search/${value}`
                                    );
                                    toggleUI('search-panel');
                                }}
                                className={s.searchButton}
                                data-testid="control-search-button"
                            >
                                <Right />
                            </button>
                        )}
                    </div>
                </div>
            </DrawerPanel>
        </Drawer>
    );
};

export default SearchPanel;
