'use client';

import React, { useEffect } from 'react';
import DismissibleCountryNoticeRibbon from '@app/components/ui/dismissible-country-notice-ribbon/dismissible-country-notice-ribbon';
import DismissibleAnnouncementRibbon from '@app/components/ui/dismissible-announcement-ribbon/dismissible-announcement-ribbon';
import LoopReturnsRibbon from '@app/components/ui/loop-returns-ribbon/loop-returns-ribbon';
import getIsDismissedCookieValue from '@app/components/ui/utils/get-is-dissmissed-cookie-value';
import mapCountryNameToRegionCode from '@app/lib/vercel/map-country-name-to-region-code';
import useLoopReturns from '@app/lib/loop-returns/state';
import s from './ribbons.module.css';
import clsx from 'clsx';

const Ribbons = ({ globalConfig, countryCode }) => {
    const [userCountryCode, setUserCountryCode] = React.useState<string | null>(
        null
    );
    const { isCreditShoppingActive } = useLoopReturns((state) => ({
        isCreditShoppingActive: state.isCreditShoppingActive,
    }));
    const [isMounted, setIsMounted] = React.useState(false);
    const [isCountryNoticeDismissed, setIsCountryNoticeDismissed] =
        React.useState(getIsDismissedCookieValue('Country Notice Ribbon'));
    const [isAnnouncementDismissed, setIsAnnouncementDismissed] =
        React.useState(getIsDismissedCookieValue('Announcement Ribbon'));

    useEffect(() => {
        if (isCreditShoppingActive) {
            setIsMounted(true);
            return;
        }
        if (!isCreditShoppingActive && !isCountryNoticeDismissed) {
            const fetchRegionCode = async () => {
                await fetch('/api/geolocation')
                    .then((response) => response.json())
                    .then((data) => {
                        setUserCountryCode(data.country);
                    })
                    .catch((error) => {
                        console.log("Couldn't fetch user's region code", error);
                    });
                setIsMounted(true);
            };

            fetchRegionCode();
        }
    }, [countryCode, isCreditShoppingActive, isCountryNoticeDismissed]);

    const renderDismissibleAnnouncementRibbon = () => {
        return (
            <DismissibleAnnouncementRibbon
                announcement={globalConfig?.ribbonAnnouncement}
                countryCode={countryCode}
                setIsAnnouncementDismissed={setIsAnnouncementDismissed}
            />
        );
    };

    if (isCreditShoppingActive) {
        return <LoopReturnsRibbon />;
    }

    if (!isMounted) return null;

    let isCountryMismatch = false;
    if (userCountryCode) {
        isCountryMismatch =
            countryCode !== mapCountryNameToRegionCode(userCountryCode);
    }

    if (!isCountryNoticeDismissed && userCountryCode && isCountryMismatch) {
        return (
            <DismissibleCountryNoticeRibbon
                contextCountryCode={countryCode}
                clientCountryCode={userCountryCode}
                setIsCountryNoticeDismissed={setIsCountryNoticeDismissed}
            />
        );
    }

    if (globalConfig?.ribbonAnnouncement && !isAnnouncementDismissed) {
        return (
            <>
                <div
                    className={clsx({
                        [s.desktopOnly]: globalConfig.ribbonAnnouncementMobile,
                    })}
                >
                    {renderDismissibleAnnouncementRibbon()}
                </div>
                {globalConfig.ribbonAnnouncementMobile && (
                    <div className={s.mobileOnly}>
                        {renderDismissibleAnnouncementRibbon()}
                    </div>
                )}
            </>
        );
    }

    return null;
};

export default Ribbons;
