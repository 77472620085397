'use client';
import React, { Suspense, useEffect, useState } from 'react';
import { useParams, useRouter } from 'next/navigation';
import { getContext } from '@app/lib/store-context/get-context';
import getCurrencySymbol from '@app/lib/helpers/get-currency-symbol';
import { Language } from '@app/lib/store-context/Context';
import {
    ContentStructure,
    ContentStructureNavItem,
    useContentStructure,
} from '@app/lib/content-structure';
import useUI from '@app/components/ui/state';
import useDrawerDirection from '@app/lib/hooks/use-drawer-direction';
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerPanel,
} from '@app/components/ui/drawer/Drawer';
import CatalogueNavItems from '@app/components/nav-sidebar/catalogue-nav-items';
import { HeaderNavCallbacks } from '@app/components/header-nav/types';
import HeaderNavItems from '@app/components/header-nav/header-nav-items';
import NavFeatureGroup from '@app/components/nav-feature-group/nav-feature-group';
import s from './nav-sidebar-desktop.module.css';
import cn from 'clsx';
import localisePath from '@app/lib/helpers/localise-path';
import arrayHasDuplicates from '@app/lib/helpers/array-has-duplicates';
import type { NavCards } from '../managed-navigation/managed-navigation';
import useLoopReturns from '@app/lib/loop-returns/state';
import NavCard from '@app/components/nav-card/nav-card';
import StoryFeatureGroup from '../nav-feature-group/story-feature-group';
import capitalize from 'lodash.capitalize';
import lowerCase from 'lodash.lowercase';

const NavSidebarDesktop = ({
    contentStructure,
    navCards,
    storiesCards,
    activeNavItem = null,
    callbacks,
}: {
    callbacks: HeaderNavCallbacks;
    navCards: NavCards | null;
    storiesCards: NavCards | null;
    activeNavItem: ContentStructureNavItem | null;
    contentStructure: ContentStructure;
}) => {
    const { openUIKey, setActiveNavItem } = useUI((state) => {
        return {
            openUIKey: state.openUIKey,
            setActiveNavItem: state.setActiveNavItem,
        };
    });
    const router = useRouter();
    const { lang } = useParams();
    const { isCreditShoppingActive } = useLoopReturns((state) => ({
        isCreditShoppingActive: state.isCreditShoppingActive,
    }));
    const { toggleUI } = useUI();
    const drawerDirection = useDrawerDirection('left', 'left');
    const isDrawerOpen = openUIKey === 'main-menu-desktop';
    const context = getContext(lang as Language);
    const { currencyCode } = context;
    const { structure, getPathsForNode, getBranchLengthForNode } =
        useContentStructure(contentStructure);
    const [activeNodePath, setActiveNodePath] = useState<
        ContentStructureNavItem[] | null
    >(null);
    const [selectedNode, setSelectedNode] =
        useState<null | ContentStructureNavItem>();
    const [items, setItems] = useState<ContentStructure>(structure);
    const [openCluster, setOpenCluster] = useState<null | string>(null);
    const [activeFeaturePanel, setActiveFeaturePanel] = useState<null | string>(
        null
    );
    useEffect(() => {
        if (activeNavItem) {
            let preselectedItemPath = getPathsForNode(activeNavItem);

            // We need to dedupe the tier 1 array of objects, because it can end up with
            // multiple instances in it for things like Bundles which appear many times
            // in the content structure.
            // We need instances which have children, otherwise, when it goes through the ELSE
            // below, the last instance with no children well end up being the selected node,
            // and the children won't show up in the nav correctly.
            if (arrayHasDuplicates(preselectedItemPath)) {
                const preselectedItemPathWithChildren =
                    preselectedItemPath.find(
                        (item: ContentStructureNavItem) => {
                            item.children;
                        }
                    );
                if (preselectedItemPathWithChildren) {
                    preselectedItemPath = [preselectedItemPathWithChildren];
                } else {
                    preselectedItemPath = [preselectedItemPath[0]];
                }
            }

            if (preselectedItemPath.length === 1) {
                // There's only one possible path for this node.
                setSelectedNode(preselectedItemPath[0]);
            } else {
                preselectedItemPath.forEach((item: ContentStructureNavItem) => {
                    const itemBranchLength =
                        getBranchLengthForNode(activeNavItem);
                    const pathsForItem = getPathsForNode(item);
                    const currentPosition = pathsForItem.length;
                    const position: number = itemBranchLength - currentPosition;
                    if (position > 1) {
                        setSelectedNode(item);
                    }
                    if (position === 1) {
                        setOpenCluster(item.label);
                    }
                });
            }
        }
    }, [activeNavItem]);

    useEffect(() => {
        if (selectedNode) {
            const activeNodePath: ContentStructureNavItem[] =
                getPathsForNode(selectedNode);
            setActiveNodePath(activeNodePath);
            if (selectedNode.children) {
                setItems(selectedNode.children);
            }
            if (selectedNode.hrefLink === '/labs' && selectedNode.children) {
                setItems([]);
            }
        }
    }, [selectedNode]);

    const isStoriesNav = activeNavItem?.hrefLink === '/stories';
    const storiesCardCategories =
        storiesCards?.stories?.map((story) =>
            capitalize(lowerCase(story.category))
        ) || [];

    const onSetOpenCluster = (cluster: string) => {
        if (
            [
                'Collaborations',
                'Collections',
                ...storiesCardCategories,
            ].includes(cluster)
        ) {
            const panelId = `${cluster.toLowerCase()}-${activeNavItem?.gender}`;
            if (activeFeaturePanel === panelId) setActiveFeaturePanel(null);
            else {
                setActiveFeaturePanel(panelId);
                setOpenCluster(null);
            }
        } else {
            setOpenCluster(cluster);
            setActiveFeaturePanel(null);
        }
    };
    const currencySymbol = getCurrencySymbol(lang as Language, currencyCode);
    const desktopCallbacks = Object.assign({}, callbacks);
    desktopCallbacks.toggleItem = (item: ContentStructureNavItem) => {
        if (!item.children) {
            router.push(
                localisePath(`${item.hrefLink}`, lang as Language) as string
            );
            toggleUI('main-menu-desktop');
        } else {
            setActiveNavItem(item);
            setActiveFeaturePanel(null);
        }
    };
    desktopCallbacks.toggleMenu = () => {
        toggleUI('main-menu-desktop');
    };
    const langAsCountry = (language: Language) => {
        if (language) {
            return language.split('-')[1];
        }
        return 'AU'; // Its really only the framework level 404 page that should encounter this.
    };

    return (
        <Drawer open={isDrawerOpen} onOpenChange={desktopCallbacks.toggleMenu}>
            <DrawerPanel
                inFrom={drawerDirection}
                variant="catalogue-nav-desktop"
                className={cn({
                    [s.wideDrawer]: activeFeaturePanel,
                })}
            >
                <div className={s.panels}>
                    <div className={s.panel}>
                        <DrawerHeader
                            className="mmds-copy-three"
                            closeButtonVariant="text"
                        >
                            <HeaderNavItems
                                items={contentStructure}
                                activeItem={activeNavItem}
                                callbacks={desktopCallbacks}
                                lang={lang as Language}
                                compact
                            />
                        </DrawerHeader>
                        <DrawerBody>
                            <CatalogueNavItems
                                contentStructure={contentStructure}
                                items={items}
                                callbacks={desktopCallbacks}
                                activeNavItem={activeNavItem}
                                activeNodePath={activeNodePath}
                                openCluster={openCluster}
                                setOpenCluster={onSetOpenCluster}
                                setSelectedNode={setSelectedNode}
                                selectedNode={selectedNode}
                                lang={lang as Language}
                                activeFeaturePanel={activeFeaturePanel}
                            />
                        </DrawerBody>
                        {activeNavItem?.hrefLink === '/labs' && navCards && (
                            <div className={cn(s.storesFeature)}>
                                <Suspense
                                    fallback={<div>Loading features...</div>}
                                >
                                    {navCards?.stores.map((navCard, index) => (
                                        <NavCard
                                            key={index}
                                            ctaText={navCard.ctaText}
                                            ctaLink={navCard.ctaLink}
                                            ctaIcon="northeast"
                                            image={navCard.image}
                                            lang={lang as Language}
                                        />
                                    ))}
                                </Suspense>
                            </div>
                        )}
                        {!isCreditShoppingActive && (
                            <DrawerFooter className="mmds-copy-three">
                                <button
                                    onClick={callbacks.toggleLanguageSwitch}
                                >
                                    <span>
                                        {langAsCountry(lang as Language)} /{' '}
                                        {currencyCode} {currencySymbol}
                                    </span>
                                </button>
                            </DrawerFooter>
                        )}
                    </div>
                    {activeFeaturePanel && navCards && !isStoriesNav && (
                        <div
                            className={cn(
                                s.panel,
                                s.features,
                                s.featuresPadding
                            )}
                        >
                            <DrawerBody>
                                <NavFeatureGroup
                                    groupID={activeFeaturePanel}
                                    navCards={navCards}
                                    lang={lang as Language}
                                />
                            </DrawerBody>
                        </div>
                    )}
                    {activeFeaturePanel && storiesCards && isStoriesNav && (
                        <div className={cn(s.panel, s.features)}>
                            <DrawerBody className={s.drawerBody}>
                                <StoryFeatureGroup
                                    groupID={activeFeaturePanel}
                                    storiesCards={storiesCards}
                                    lang={lang as Language}
                                />
                            </DrawerBody>
                        </div>
                    )}
                </div>
            </DrawerPanel>
        </Drawer>
    );
};

export default NavSidebarDesktop;
