// Create a Providers component to wrap your application with all the components requiring 'use client'
'use client';

import NextTopLoader from 'nextjs-toploader';

const Providers = ({ children }) => {
    return (
        <>
            {children}
            <NextTopLoader
                showSpinner={false}
                color="var(--color-brand-pay)"
                zIndex={6}
                height={2}
            />
        </>
    );
};

export default Providers;
