import { gql } from 'graphql-request';
import { CART_FIELDS } from '@app/lib/shopify/queries/fragments/cart-fields';

export const cartAttributesUpdate = gql`
    mutation cartAttributesUpdate(
        $country: CountryCode
        $cartId: ID!
        $attributes: [AttributeInput!]!
    ) @inContext(country: $country) {
        cartAttributesUpdate(cartId: $cartId, attributes: $attributes) {
            userErrors {
                message
                field
            }
            cart {
                ...cartFields
            }
        }
    }
    ${CART_FIELDS}
`;
