'use client';
import { useEffect } from 'react';
import { captureException } from '@sentry/nextjs';
import Cart from '@app/lib/types/Cart';
import { trimGID } from '@app/lib/shopify/helpers/gid';

const CartTracking = ({ cart }: { cart: Cart | null }) => {
    useEffect(() => {
        if (cart !== null && cart.lines.edges.length) {
            try {
                const nostoCartItems = cart.lines.edges.map((cartItem) => ({
                    name: cartItem.node.merchandise.title,
                    price_currency_code:
                        cartItem.node.merchandise.price.currencyCode,
                    product_id: trimGID(
                        cart.lines.edges[0].node.merchandise.product.id
                    ),
                    quantity: cartItem.node.quantity,
                    sku_id: cartItem.node.merchandise.sku,
                    unit_price: Number(cartItem.node.merchandise.price.amount),
                }));
                typeof (window as any).nostojs === 'function' &&
                    (window as any).nostojs((api) => {
                        api.defaultSession().setCart({
                            items: nostoCartItems,
                        });
                    });
            } catch (e) {
                captureException(e);
            }
        }
    }, [cart]);

    return null;
};

export default CartTracking;
