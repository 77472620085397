'use client';

import React, { lazy, useEffect } from 'react';
import {
    ContentStructure,
    useContentStructure,
} from '@app/lib/content-structure';
import { HeaderNavCallbacks } from '../header-nav/types';
import HeaderNav from '../header-nav';
import useUI from '@app/components/ui/state';
import { useParams, useRouter } from 'next/navigation';
import NavSidebarMobile from '@app/components/nav-sidebar/nav-sidebar-mobile';
import NavSidebarDesktop from '@app/components/nav-sidebar/nav-sidebar-desktop';
import localisePath from '@app/lib/helpers/localise-path';
import { Language } from '@app/lib/store-context/Context';

const Warning = lazy(() => import('@app/components/warning/warning'));

export interface NavCard {
    image: string;
    ctaText: string;
    ctaLink: string;
}
export interface StoryNavCard {
    category: string;
    featured: string[];
}

export interface NavCards {
    mensCollections: NavCard[];
    womensCollections: NavCard[];
    mensCollaborations: NavCard[];
    womensCollaborations: NavCard[];
    stores: NavCard[];
    stories: StoryNavCard[]
}

const ManagedNavigation = ({
    contentStructure,
    navCards,
    storiesCards,
}: {
    contentStructure: ContentStructure;
    navCards: NavCards | null;
    storiesCards: NavCards | null
}) => {
    const router = useRouter();
    const { lang } = useParams();
    const { toggleUI, setActiveNavItem, activeNavItem } = useUI((state) => ({
        toggleUI: state.toggleUI,
        setActiveNavItem: state.setActiveNavItem,
        activeNavItem: state.activeNavItem,
    }));
    const { structure } = useContentStructure(contentStructure);

    useEffect(() => {
        localStorage?.setItem('stories:cache', JSON.stringify(storiesCards));
    },[storiesCards])
    
    if (!structure.length) {
        return (
            <Warning>
                Main Nav: Navigation "[
                {process.env.NEXT_PUBLIC_SHOPIFY_MAIN_MENU_HANDLE}]" has not
                been configured in this region's Shopify instance yet. IA could
                not be generated.
            </Warning>
        );
    }
    const headerCallbacks: HeaderNavCallbacks = {
        toggleSupport: () => toggleUI('support-menu'),
        toggleMenu: () => toggleUI('main-menu-mobile'),
        toggleSearch: () => toggleUI('search-panel'),
        toggleLanguageSwitch: () => toggleUI('language-switch'),
        toggleItem: (item) => {
            if (!item.children) {
                router.push(
                    localisePath(`${item.hrefLink}`, lang as Language) as string
                );
            } else {
                setActiveNavItem(item);
                toggleUI('main-menu-desktop');
            }
        },
        toggleCart: () => {
            toggleUI('cart');
        },
        toggleProfile: () => toggleUI('profile'),
    };

    return (
        <>
            <HeaderNav
                variant="responsive"
                items={structure}
                callbacks={headerCallbacks}
                activeItem={activeNavItem}
                lang={lang as Language}
            />
            <NavSidebarMobile
                contentStructure={contentStructure}
                callbacks={headerCallbacks}
                activeNavItem={activeNavItem}
                navCards={navCards}
                storiesCards={storiesCards}
            />
            <NavSidebarDesktop
                contentStructure={contentStructure}
                callbacks={headerCallbacks}
                activeNavItem={activeNavItem}
                navCards={navCards}
                storiesCards={storiesCards}
            />
        </>
    );
};

export default ManagedNavigation;
