import React, { useState } from 'react';
import { AttributeInput } from 'shopify-storefront-api-typings';
import CartLineItemEdge from '@app/lib/types/CartLineItemEdge';
import { CartLine, CartLineProps } from 'mmds';
import removeLineItems from '@app/lib/shopify/methods/remove-from-cart';
import updateLineItemQuantity from '@app/lib/shopify/methods/update-line-item-quantity';
import { ShopifyClientContext } from '@app/lib/shopify/client';
import useCart from '@app/[lang]/cart/state';
import useManageGift from '../hooks/use-manage-gift';
import Linker from '@app/components/linker/linker';
import { Language } from '@app/lib/store-context/Context';
import removeFromCartGTMEvent from '@app/lib/events/remove-from-cart.gtm';
import addToCartGTMEvent from '@app/lib/events/add-to-cart.gtm';
import addToCartPixelEvent from '@app/lib/events/add-to-cart.pixel';
import addToCartKlaviyoEvent from '@app/lib/events/add-to-cart.klaviyo';
import { usePathname } from 'next/navigation';
import { handleUserErrors } from '@app/lib/helpers/handle-user-errors';
import type CartLineItem from '@app/lib/types/CartLineItem';
import type { CartLinesUpdateResponse } from '@app/lib/shopify/methods/update-line-item-quantity';
import useWishlist from '@app/lib/wishlist/state';

const getBundleId = (lineItemNode: CartLineItem): string | null => {
    return (
        lineItemNode.attributes.find(
            (attribute: AttributeInput) => attribute.key === '_bundle_id'
        )?.value || null
    );
};

const getGiftId = (lineItemNode: CartLineItem) => {
    const isGift = lineItemNode.attributes.some(
        (attribute: AttributeInput) => attribute.key === 'gift'
    );
    if (isGift) {
        return lineItemNode.id;
    }
    return null;
};

export default function CartLineItem({
    lineItemEdge,
    priceFormatter,
    clientSettings,
    compact = false,
    lang,
    gift,
    isCreditShoppingActive,
}: {
    lineItemEdge: CartLineItemEdge;
    priceFormatter: Intl.NumberFormat;
    clientSettings: ShopifyClientContext;
    compact: boolean;
    lang: Language;
    gift?: any; // TODO
    isCreditShoppingActive: boolean;
}) {
    const pathname = usePathname();
    const { cart, setCart } = useCart();
    const { addItem } = useWishlist((state) => ({
        addItem: state.addItem,
    }));
    useManageGift(gift);

    const [errorMessages, setErrorMessages] = useState<{
        [key: string]: string | null;
    }>({});
    const setErrorMessage = (field: string, message: string | null) => {
        setErrorMessages((prev) => ({ ...prev, [field]: message }));
    };

    const lineItem = lineItemEdge.node;
    if (!cart || !lineItem || !lineItem.merchandise) return null;
    const { cost, quantity, merchandise, discountAllocations } = lineItem;
    const { title } = merchandise.product;
    const isUserInCreditShoppingMode = isCreditShoppingActive;

    const discountAmount = discountAllocations.reduce((total, allocation) => {
        return total + parseFloat(allocation?.discountedAmount?.amount);
    }, 0);

    const eventAttributes = {
        currencyCode: merchandise.price.currencyCode,
        name: title,
        id: merchandise.id,
        price: merchandise.price.amount,
        brand: 'MAAP',
        category: 'Apparel & Accessories',
        quantity: 1,
        variant: merchandise.title,
        item_id: merchandise.product.handle,
        item_variant: merchandise.sku as string,
    };

    const pixelEventAttributes = {
        content_name: title,
        content_ids: [merchandise.id],
        content_category: 'Apparel & Accessories',
        content_type: 'product',
        value: merchandise.price.amount,
        currency: merchandise.price.currencyCode,
        quantity: 1,
    };

    const klaviyoEventAttributes = {
        productPrice: merchandise.price.amount,
        productTitle: title,
        productId: merchandise.id,
        variantSku: merchandise.sku as string,
        productCategory: 'Apparel & Accessories',
        imageUrl: merchandise?.image?.transformedSrc,
        productURL: pathname,
        productCompareAtPrice: merchandise.compareAtPrice,
        checkoutUrl: cart.checkoutUrl,
        quantity: 1,
    };

    const compareAtPriceMultiplier = discountAmount ? 1 : quantity;
    const compareAtPrice =
        merchandise?.compareAtPrice?.amount || cost.subtotalAmount.amount;
    const discountAmountPercent =
        ((discountAmount || compareAtPrice - cost.subtotalAmount.amount) /
            compareAtPrice) *
        100;

    const showCompareAtPrice = compareAtPrice > cost.totalAmount.amount;
    const cartLineProps: CartLineProps = {
        compact: compact,
        id: lineItem.id,
        handle: merchandise.product.handle,
        sku: merchandise.sku as string,
        title,
        price: priceFormatter.format(
            parseFloat(merchandise.price.amount) * quantity - discountAmount
        ),
        compareAtPrice: showCompareAtPrice
            ? priceFormatter.format(
                  parseFloat(compareAtPrice) * compareAtPriceMultiplier
              )
            : null,
        currencyCode: merchandise.price.currencyCode,
        quantity,
        imageSrc: merchandise.image?.transformedSrc || '',
        size:
            merchandise.selectedOptions.find(
                (option: any) => option.name === 'Size'
            )?.value || 'One size',
        colour:
            merchandise.selectedOptions.find(
                (option: any) => option.name === 'Color'
            )?.value || '',
        discountMessage: !isUserInCreditShoppingMode
            ? discountAmountPercent
                ? // ? `${Math.round(discountAmountPercent)}% discount applied.`
                  'Discount Applied'
                : null
            : null,
        bundleID: getBundleId(lineItem),
        giftID: getGiftId(lineItem),
        isUserInCreditShoppingMode: isUserInCreditShoppingMode,
        callbacks: {
            removeFromCart: (lineID: string) => {
                removeLineItems(cart.id, [lineID], clientSettings).then(
                    (newCart) => {
                        if (newCart) {
                            setCart(newCart);
                            removeFromCartGTMEvent(eventAttributes);
                        }
                    }
                );
            },
            removeGift: (giftID: string) => {
                removeLineItems(cart.id, [giftID], clientSettings).then(
                    (newCart) => {
                        if (newCart) {
                            setCart(newCart);
                            sessionStorage.setItem(
                                'GIFT_UNWANTED_VARIANT_ID',
                                giftID
                            );
                        }
                    }
                );
            },
            removeBundle: (bundleID: string) => {
                const getBundleItems = (bundleID: string) => {
                    return cart.lines.edges.filter((edge: any) => {
                        const { node } = edge;
                        const { attributes } = node;
                        return attributes.find((attribute: any) => {
                            return (
                                attribute.key === '_bundle_id' &&
                                attribute.value === bundleID
                            );
                        });
                    });
                };

                const bundleLineItemIds = getBundleItems(bundleID).map(
                    (edge: any) => edge.node.id
                );

                removeLineItems(
                    cart.id,
                    bundleLineItemIds,
                    clientSettings
                ).then((newCart) => {
                    if (newCart) {
                        setCart(newCart);
                        removeFromCartGTMEvent(eventAttributes);
                    }
                });
            },
            addToWishlist: (sku: string, handle: string) => {
                addItem({ sku, handle });
            },
            updateQuantity: async (lineID: string, quantity: string) => {
                try {
                    const response: CartLinesUpdateResponse =
                        await updateLineItemQuantity(
                            clientSettings,
                            cart.id,
                            lineID,
                            quantity.toString()
                        );
                    if (response.userErrors.length > 0) {
                        handleUserErrors(response.userErrors, setErrorMessage);
                    } else {
                        if (!response.cart) {
                            console.log('No cart found in response.');
                            return;
                        }
                        setCart(response.cart);
                        setErrorMessages({});

                        addToCartGTMEvent({
                            ...eventAttributes,
                            quantity: parseInt(quantity),
                        });
                        addToCartPixelEvent({
                            ...pixelEventAttributes,
                            quantity: parseInt(quantity),
                        });
                        addToCartKlaviyoEvent(
                            {
                                ...klaviyoEventAttributes,
                                quantity: parseInt(quantity),
                            },
                            false
                        );
                    }
                } catch (error) {
                    console.error('Unexpected error:', error);
                }
            },
        },
        lang,
        Linker: Linker,
        errorMessages,
    };

    if (merchandise.compareAtPrice) {
        cartLineProps.compareAtPrice = priceFormatter.format(
            parseFloat(merchandise.compareAtPrice.amount) * quantity
        );
    }

    return <CartLine {...cartLineProps} />;
}
