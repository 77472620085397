import React from 'react';
import useUI from '@app/components/ui/state';
import useProfile from '@app/lib/profile/state';
import cn from 'clsx';
import s from './cart-wishlist-login-cta.module.css';
const CartWishlistLoginCta = ({
    variant = 'sidebar',
}: {
    variant?: 'sidebar' | 'page';
}) => {
    const { authStatus } = useProfile();
    const { toggleUI } = useUI();
    if (authStatus === 'AUTHENTICATED') {
        return null;
    }

    return (
        <div
            className={cn(s.root, s.warning, {
                [s.sidebar]: variant === 'sidebar',
                [s.page]: variant === 'page',
            })}
        >
            <div className={s.text}>
                <p className="mmds-copy-three-serif">
                    <span
                        className={cn(s.login, 'mmds-component-one-link')}
                        onClick={() => toggleUI('profile')}
                    >
                        Log in
                    </span>{' '}
                    to save your Wishlist to your profile
                </p>
            </div>
        </div>
    );
};

export default CartWishlistLoginCta;
