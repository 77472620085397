'use client';
import { useEffect, useState } from 'react';
import Script from 'next/script';
import { AttributeInput } from 'shopify-storefront-api-typings';
import Cart from '../types/Cart';
import useProfile, { AuthStatus } from '../profile/state';
import { useParams, useSearchParams } from 'next/navigation';
import storeClickID from '@app/lib/impact-tracking/store-clickid';
import useCart from '@app/[lang]/cart/state';
import addCustomAttributesToCart from '@app/lib/shopify/methods/add-custom-attributes-to-cart';
import { getShopifyClientSettings } from '@app/lib/shopify/get-client-settings';
import { Language } from '@app/lib/store-context/Context';
import retrieveStoredClickID from '@app/lib/impact-tracking/retrieve-stored-clickid';
import deleteStoredClickid from '@app/lib/impact-tracking/delete-stored-clickid';
import getCartId from '../shopify/checkout/get-cart-id';

interface ScriptParams {
    customerId: string;
    customerEmail: string;
    customProfileId: string;
}

const ImpactTracking = () => {
    const { lang } = useParams();
    const clientSettings = getShopifyClientSettings(lang as Language);
    const { cart, setCart } = useCart((state) => ({
        cart: state.cart,
        setCart: state.setCart,
    }));
    const searchParams = useSearchParams();
    const queryToken = searchParams.get('token');
    // The irclickid (clickid) is a param which is sent to the MAAP site from some impact redirects that
    // tracks the origin click event and should be sent to the checkout conversion script as "ClickId".
    const impactClickID =
        retrieveStoredClickID() || searchParams.get('irclickid');
    const [isComponentReady, setIsComponentReady] = useState(false);
    const { sessionUUID, profile, authStatus, checkUserSession } = useProfile(
        (state) => ({
            sessionUUID: state.sessionUUID,
            profile: state.profile,
            authStatus: state.authStatus,
            checkUserSession: state.checkUserSession,
        })
    );
    const [scriptParams, setScriptParams] = useState<ScriptParams | null>({
        customerId: '',
        customerEmail: '',
        customProfileId: '',
    });

    useEffect(() => {
        if (cart) {
            const storedCartId = getCartId(lang as Language);
            if (cart.id !== storedCartId) {
                deleteStoredClickid();
            } else {
                let customAttributes: AttributeInput[] = [];
                if (sessionUUID) {
                    customAttributes.push({
                        key: 'customProfileID',
                        value: sessionUUID,
                    });
                }
                if (profile) {
                    customAttributes.push({
                        key: 'customerID',
                        value: profile.id,
                    });
                    customAttributes.push({
                        key: 'customerEmail',
                        value: profile.hashedEmail,
                    });
                }
                if (impactClickID) {
                    customAttributes.push({
                        key: 'impactClickID',
                        value: impactClickID,
                    });
                }
                if (customAttributes.length > 0) {
                    addCustomAttributesToCart(
                        clientSettings,
                        cart.id,
                        customAttributes
                    ).then((cart: Cart | null) => {
                        if (cart) setCart(cart);
                    });
                }
            }
        }
    }, [impactClickID, profile, sessionUUID, cart?.id]);

    useEffect(() => {
        if (impactClickID) {
            storeClickID(impactClickID);
        }
    }, [impactClickID]);

    useEffect(() => {
        if (!queryToken) {
            checkUserSession();
        }
    }, [queryToken]);

    useEffect(() => {
        if (sessionUUID) {
            if (authStatus === AuthStatus.AUTHENTICATED && profile) {
                setScriptParams({
                    customerId: profile.id,
                    customerEmail: profile.hashedEmail,
                    customProfileId: sessionUUID,
                });
                setIsComponentReady(true);
            } else if (authStatus === AuthStatus.UNAUTHENTICATED) {
                setScriptParams((prevParams) => {
                    if (!prevParams) return null;
                    return {
                        ...prevParams,
                        customProfileId: sessionUUID,
                    };
                });
                setIsComponentReady(true);
            }
        }
    }, [sessionUUID, profile, authStatus]);

    if (isComponentReady) {
        return (
            <>
                <Script
                    id="impact-universal"
                    strategy="lazyOnload"
                    dangerouslySetInnerHTML={{
                        __html: `(function(a,b,c,d,e,f,g){e['ire_o']=c;e[c]=e[c]||function(){(e[c].a=e[c].a||[]).push(arguments)};f=d.createElement(b);g=d.getElementsByTagName(b)[0];f.async=1;f.src=a;g.parentNode.insertBefore(f,g);})('https://utt.impactcdn.com/A5400477-16c3-42bb-98d4-d0b6dd8834331.js','script','ire',document,window);`,
                    }}
                />
                <Script
                    id="impact-identify"
                    strategy="lazyOnload"
                    dangerouslySetInnerHTML={{
                        __html: `ire('identify', {customerId: '${scriptParams?.customerId}', customerEmail: '${scriptParams?.customerEmail}', customProfileId: '${scriptParams?.customProfileId}'});`,
                    }}
                />
            </>
        );
    }
};

export default ImpactTracking;
