'use client';

import React, { useEffect, useRef, useState } from 'react';
import {
    useRouter,
    usePathname,
    useSearchParams,
    useParams,
} from 'next/navigation';
import setIDSToken from '@app/lib/profile/set-ids-token';
import getIDSToken from '@app/lib/profile/get-ids-token';
import useProfile from '@app/lib/profile/state';
import useWishlist from '@app/lib/wishlist/state';
import { getContext } from '@app/lib/store-context/get-context';
import { Language } from '@app/lib/store-context/Context';
import useWindowMessage from '@lib/hooks/useWindowMessage';
import s from './profile-portal.module.css';
import { Button } from 'mmds';

type ProfilePortalProps = {
    toggleUI: () => void;
};

const YOUR_PROFILE_PORTAL_BASE_URL = 'https://my.maap.cc';

const getOmneoRegionCode = (language: Language) => {
    const context = getContext(language as Language);
    return context.countryCode?.toLowerCase() === 'us'
        ? 'usa'
        : context.countryCode?.toLowerCase() === 'au'
        ? 'intl'
        : context.countryCode?.toLowerCase();
};

export const ProfilePortal: React.FC<ProfilePortalProps> = ({ toggleUI }) => {
    const params = useParams();
    const pathname = usePathname();
    const router = useRouter();
    const searchParams = useSearchParams();
    const { clearProfile, checkUserSession, setAuthToken } = useProfile();
    const [portalURL, setPortalUrl] = useState<string>(
        YOUR_PROFILE_PORTAL_BASE_URL
    );
    const { merge, isMerged, itemCount } = useWishlist();
    const portalFrameRef = useRef<any>(null);
    const [frameLoaded, setFrameLoaded] = useState(false);

    const queryToken = searchParams.get('token');
    const language = params.lang as Language;

    const constructPortalURL = (): string => {
        const url = `${portalURL}?region=${getOmneoRegionCode(language)}`;

        if (queryToken) {
            return `${url}&token=${queryToken}`;
        }

        return url;
    };

    useEffect(() => {
        setPortalUrl(constructPortalURL());
        if (queryToken) {
            setIDSToken(queryToken);
            setAuthToken(queryToken);
        }
        checkUserSession();
    }, [queryToken, params.lang]);

    // Already authenticated and reopening the sidebar.
    useEffect(() => {
        const token = getIDSToken();
        if (token) {
            setPortalUrl(constructPortalURL());
        }
        const handleProfilePortalMessage = (event: any) => {
            if (event.origin !== YOUR_PROFILE_PORTAL_BASE_URL) return;
            if (event.data === 'login') {
                // TODO: Learnq event
                // If there are local wishlist items at time of login, merge with the remote list.
                if (itemCount && !isMerged) merge();
            }
            if (event.data === 'logout') {
                clearProfile();
                setIDSToken(null);
            }
        };
        window.addEventListener('message', handleProfilePortalMessage);
        return () => {
            window.removeEventListener('message', handleProfilePortalMessage);
        };
    }, []);

    useWindowMessage({
        login: (data: any) => {
            const cleanedSearchParams = new URLSearchParams(
                searchParams.toString()
            );
            cleanedSearchParams.delete('token');
            router.replace(`${pathname}?${cleanedSearchParams}`);
        },
        getUrl: (data: any) => {
            const postMessageData = {
                url: window.location.href,
                action: data.action,
            };
            portalFrameRef?.current?.contentWindow?.postMessage(
                postMessageData,
                YOUR_PROFILE_PORTAL_BASE_URL
            );
        },
    });

    return (
        <>
            {!frameLoaded && (
                <p className="mmds-component-one-detail">One moment...</p>
            )}
            <span className={s.closeButton}>
                <Button
                    icon="close"
                    variant="text"
                    onClick={toggleUI}
                    data-testid="control-profile-panel-close"
                >
                    <span>Close</span>
                </Button>
            </span>
            <iframe
                id="pp_iframe"
                ref={portalFrameRef}
                src={portalURL}
                onLoad={() => setFrameLoaded(true)}
                className={s.iframe}
            />
        </>
    );
};

export default ProfilePortal;
