'use client';

import React from 'react';
import {
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerPanel,
} from '@app/components/ui/drawer/Drawer';
import useUI from '@app/components/ui/state';
import Linker from '@app/components/linker/linker';
import { useParams } from 'next/navigation';
import { Language } from '@app/lib/store-context/Context';
import s from './support-nav.module.css';
import { Button, Left } from 'mmds';

export const SupportNav = () => {
    const { lang } = useParams();
    const { openUIKey, toggleUI } = useUI();
    const isDrawerOpen = openUIKey === 'support-menu';
    const menuCallback = {
        toggleMenu: () => toggleUI('main-menu-mobile'),
    };

    return (
        <Drawer
            open={isDrawerOpen}
            onOpenChange={() => toggleUI('support-menu')}
        >
            <DrawerPanel inFrom="left" variant="catalogue-nav">
                <DrawerHeader closeButtonVariant="text">
                    <div className={s.navHeader}>
                        <button
                            onClick={menuCallback.toggleMenu}
                            className={s.navLeftIcon}
                        >
                            <Left />
                        </button>
                        <Button variant="quite" className={s.navHeading}>
                            Support
                        </Button>
                    </div>
                </DrawerHeader>
                <DrawerBody>
                    <div className={s.navItems}>
                        <Linker
                            href="https://support.maap.cc/hc/en-us/requests/new"
                            title="click here to contact us"
                            lang={lang as Language}
                        >
                            <Button variant="text" textVariantPadding={false}>
                                Email Us
                            </Button>
                        </Linker>
                        <Linker
                            href="tel:+61 3 9133 5881"
                            title="click here to contact us"
                            lang={lang as Language}
                        >
                            <Button variant="text" textVariantPadding={false}>
                                Phone +61 3 9133 5881
                            </Button>
                        </Linker>
                    </div>
                    <hr className={s.divider} />
                    <div className={s.navItems}>
                        <Linker
                            href="https://support.maap.cc/hc/en-us/categories/4408473873677-Shipping-Delivery"
                            title="read shipping info"
                            lang={lang as Language}
                        >
                            <Button variant="quite">
                                Shipping Information
                            </Button>
                        </Linker>
                        <Linker
                            href="https://support.maap.cc/hc/en-us/categories/360002401971-Returns-Exchanges"
                            title="open our returns info and portal"
                            lang={lang as Language}
                        >
                            <Button variant="quite">Returns</Button>
                        </Linker>
                        <Linker
                            href="https://support.maap.cc/hc/en-us/articles/360037456691-MAAP-Crash-Replacement"
                            title="ready our crash replacement information"
                            lang={lang as Language}
                        >
                            <Button variant="quite">Crash Replacement</Button>
                        </Linker>
                        <Linker
                            href="https://support.maap.cc/hc/en-us/articles/5003210185485-My-item-is-faulty-what-can-I-do"
                            title="find warranty information"
                            lang={lang as Language}
                        >
                            <Button variant="quite">
                                Faulty Goods & Warranty
                            </Button>
                        </Linker>
                        <Linker
                            href="https://support.maap.cc/hc/en-us"
                            title="frequently asked questions"
                            lang={lang as Language}
                        >
                            <Button variant="quite">FAQ</Button>
                        </Linker>
                    </div>
                </DrawerBody>
            </DrawerPanel>
        </Drawer>
    );
};

export default SupportNav;
