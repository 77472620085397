import React from 'react';
import Link from 'next/link';
import { Icon } from 'mmds';
import cn from 'clsx';
import s from '../cart-callout/cart-callout.module.css';

const CartCallout = ({
    variant = 'sidebar',
    heading,
    text,
    link,
}: {
    heading: string;
    text: string;
    link?: string;
    variant?: 'sidebar' | 'page';
}) => {
    return (
        <div
            className={cn(s.root, s.warning, {
                [s.sidebar]: variant === 'sidebar',
                [s.page]: variant === 'page',
            })}
        >
            <div className={s.text}>
                <h3 className={cn(s.title, 'mmds-copy-three')}>{heading}</h3>
                <p className="mmds-copy-three-serif">{text}</p>
                {link && (
                    <p className={cn('mmds-component-one')}>
                        <Link target="_blank" href={link}>
                            read more{' '}
                            <Icon className={s.icon} icon={'northeast'} />
                        </Link>
                    </p>
                )}
            </div>
        </div>
    );
};

export default CartCallout;
