'use client';

import React from 'react';
import useLoopReturns from '@app/lib/loop-returns/state';
import { Button } from 'mmds';
import cn from 'classnames';
import s from './loop-returns-ribbon.module.css';

const LoopReturnsRibbon = () => {
    const [isCancelling, setIsCancelling] = React.useState(false);
    const { availableCredit, currency, completeReturn } = useLoopReturns(
        (state) => ({
            availableCredit: state.availableCredit,
            currency: state.currency,
            completeReturn: state.completeReturn,
        })
    );

    return (
        <div
            role="navigation"
            className={cn(s.root)}
            data-testid={`ribbon-${name}`}
        >
            <div className={cn(s.column, s.leftColumn)}></div>
            <div className={s.column}>
                <div className={cn(s.content, 'mmds-component-one-detail')}>
                    {isCancelling && (
                        <>
                            <div className={s.title}>
                                Are you sure you want to return to normal
                                shopping?
                            </div>
                            <div className={s.description}>
                                If you cancel, you can restart your exchange
                                process anytime via the returns portal.
                            </div>
                        </>
                    )}
                    {!isCancelling && (
                        <>
                            <div className={s.title}>
                                Shopping with Exchange Credit
                            </div>
                            <div className={s.description}>
                                You have {availableCredit} {currency} exchange
                                credit, Simply add product(s) to cart to start
                                your exchange.
                            </div>
                        </>
                    )}
                </div>
            </div>
            <div className={cn(s.column, s.end, s.buttons)}>
                {!isCancelling && (
                    <Button
                        icon="close"
                        variant={'ghost'}
                        theme="light"
                        onClick={() => setIsCancelling(true)}
                    >
                        Cancel Exchange
                    </Button>
                )}
                {isCancelling && (
                    <Button
                        icon="close"
                        variant={'ghost'}
                        theme="light"
                        onClick={() => setIsCancelling(false)}
                    >
                        Back
                    </Button>
                )}
                {isCancelling && (
                    <Button
                        icon="close"
                        variant={'secondary'}
                        theme="light"
                        onClick={completeReturn}
                    >
                        Confirm
                    </Button>
                )}
            </div>
        </div>
    );
};

export default LoopReturnsRibbon;
