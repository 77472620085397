import React from 'react';
import {
    ContentStructure,
    ContentStructureNavItem,
    useContentStructure,
} from '@app/lib/content-structure';
import { ButtonVariant, NavIconName, NavItem, PatternPillCluster } from 'mmds';
import { HeaderNavCallbacks } from '@app/components/header-nav/types';
import { Language } from '@app/lib/store-context/Context';
import Linker from '@app/components/linker/linker';

const CatalogueNavItems = ({
    contentStructure,
    lang,
    items,
    callbacks,
    activeNavItem,
    activeNodePath,
    openCluster,
    setOpenCluster,
    setSelectedNode,
    selectedNode,
    activeFeaturePanel,
}: {
    contentStructure: ContentStructure;
    lang: Language;
    items: ContentStructureNavItem[];
    callbacks: HeaderNavCallbacks;
    activeNavItem: ContentStructureNavItem | null;
    activeNodePath: ContentStructureNavItem[] | null;
    openCluster: string | null;
    setOpenCluster: (openCluster: string | null) => void;
    setSelectedNode: (selectedNode: ContentStructureNavItem | null) => void;
    selectedNode: ContentStructureNavItem | null | undefined;
    activeFeaturePanel: string | null;
}) => {
    const { assertLoseNodeMatch, getBranchLengthForNode } =
        useContentStructure(contentStructure);
    const onSelectNavItem = (item: ContentStructureNavItem) => {
        setSelectedNode(item);
    };

    const clusterItem = (item) => {
        return item.children?.map((child: ContentStructureNavItem) => {
            let active = true;
            let icon: string | null = null;
            let buttonVariant: ButtonVariant = 'sidebar';
            if (child.label === 'Discover') {
                icon = 'discover';
                buttonVariant = 'discover';
            }
            if (child.label === 'All') {
                icon = 'northeast';
                buttonVariant = 'base';
            }
            if (
                child.hrefLink === '/collections/road-man' ||
                child.hrefLink === '/collections/road-woman' ||
                child.hrefLink === '/collections/alt-road-man' ||
                child.hrefLink === '/collections/alt-road-woman'
            ) {
                buttonVariant = 'collection';
            }

            if (activeNavItem) {
                active = assertLoseNodeMatch(child, activeNavItem);
            }

            return {
                active,
                label: child.label,
                hrefLink: child.hrefLink,
                componentType: 'button',
                onClick: callbacks.toggleMenu,
                icon,
                buttonVariant,
            };
        });
    };

    return (
        <>
            {items.map((item: ContentStructureNavItem) => {
                const isStoryNavItem =
                    item.hrefLink?.includes('/stories') && item.label !== 'All';

                const itemBranchLength = getBranchLengthForNode(item);
                const currentPosition = activeNodePath
                    ? activeNodePath.length + 1
                    : 1;
                const showCluster: boolean =
                    itemBranchLength - currentPosition === 1;

                let clusterIcon = 'down';
                if (
                    item.label === 'Collections' ||
                    item.label === 'Collaborations' ||
                    item.hrefLink === '/labs' ||
                    isStoryNavItem
                ) {
                    clusterIcon = 'right';
                }
                let tier1__active = true;
                if (selectedNode?.tier && selectedNode?.tier > 1) {
                    tier1__active = assertLoseNodeMatch(item, selectedNode);
                }
                if (openCluster) {
                    tier1__active = openCluster === item.label;
                }
                if (activeFeaturePanel) {
                    const panelId = `${item.label.toLowerCase()}-${
                        activeNavItem?.gender
                    }`;
                    tier1__active = panelId === activeFeaturePanel;
                }

                // Mobile stories
                if (item.label === 'Stories' && item.children) {
                    return (
                        <NavItem
                            key={item.label}
                            variant="l1"
                            label={item.label}
                            icon="right"
                            onSelect={() => onSelectNavItem(item)}
                            hrefLink={item.hrefLink}
                            active={tier1__active}
                        />
                    );
                }

                if (selectedNode?.label === 'Stories' && item.label !== 'All') {
                    return (
                        <NavItem
                            key={item.label}
                            variant="l1"
                            label={item.label}
                            icon="right"
                            onSelect={() => setOpenCluster(item.label)}
                            hrefLink={item.hrefLink}
                            active={tier1__active}
                        />
                    );
                }

                if ((showCluster && item.children) || isStoryNavItem) {
                    return (
                        <NavItem
                            key={item.label}
                            variant="l1"
                            label={item.label}
                            onSelect={() => {
                                if (openCluster !== item.label) {
                                    setOpenCluster(item.label);
                                } else {
                                    setOpenCluster(null);
                                }
                            }}
                            open={openCluster === item.label}
                            icon={clusterIcon as NavIconName}
                            openCluster={openCluster}
                            Linker={Linker}
                            lang={lang}
                            active={tier1__active}
                            cluster={clusterItem(item)}
                        />
                    );
                }
                if (item.label === 'By Type' && item.children) {
                    return (
                        <PatternPillCluster
                            key={item.label}
                            cluster={clusterItem(item)}
                            Linker={Linker}
                            lang={lang}
                        />
                    );
                }
                if (item.label !== 'By Type' && item.children) {
                    return (
                        <NavItem
                            key={item.label}
                            variant="l1"
                            label={item.label}
                            icon="right"
                            onSelect={() => onSelectNavItem(item)}
                            hrefLink={item.hrefLink}
                            active={tier1__active}
                        />
                    );
                }
                return (
                    <Linker
                        key={item.hrefLink}
                        href={item.hrefLink || '#'}
                        lang={lang}
                        title={`Go to ${item.label}`}
                        onClick={() => {
                            callbacks.toggleMenu();
                        }}
                    >
                        <NavItem
                            variant="l1"
                            label={item.label}
                            icon="northeast"
                            active={tier1__active}
                        />
                    </Linker>
                );
            })}
        </>
    );
};

export default CatalogueNavItems;
