import { hmacSignature } from '@arkadecx/arkade-cloud-functions-auth';

export default async function fetchProfile(idstToken: string): Promise<any> {
    const now = Date.now();
    const route = '/api/profile/fetch';
    const body = {
        idst: idstToken,
    };
    const signature = await hmacSignature(
        process.env.NEXT_PUBLIC_API_TOKEN,
        now,
        route,
        body
    );

    return fetch(route, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-authentication': `${now}:${signature}`,
        },
        body: JSON.stringify(body),
    })
        .then((response) => response.json())
        .then((response) => {
            if (response.profile) {
                return response.profile;
            }
            return null;
        })
        .catch((error) => {
            console.log('Failed to fetch profile', error);
            return null;
        });
}
