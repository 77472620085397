'use client';

import { useSearchParams } from 'next/navigation';
import { useEffect } from 'react';
import useLoopReturns from '@app/lib/loop-returns/state';
import { Language } from '@app/lib/store-context/Context';

// Test string:

// localhost:3000/?loop_return_id=8a295ea7-4402-4922-9445-b7e354783057&loop_currency=AUD&loop_total=5600&loop_base=4600&loop_credit=1000&loop_domain=maap-intl.loopreturns.com&loop_subdomain=maap-intl&loop_redirect_url=maap-intl.loopreturns.com%2F%23%2Fcredit

const LoopInitialiser = ({ lang }: { lang: Language }) => {
    const searchParams = useSearchParams();
    const {
        loadFromSearchParams,
        lang: storedLang,
        completeReturn,
    } = useLoopReturns((state) => ({
        loadFromSearchParams: state.loadFromSearchParams,
        lang: state.lang,
        completeReturn: state.completeReturn,
    }));

    const loopTotal = searchParams.get('loop_total');

    useEffect(() => {
        if (loopTotal) {
            loadFromSearchParams(searchParams, lang);
        }
    }, [loopTotal]);

    if (storedLang && storedLang !== lang) {
        // The return must have been started in a differen region and now the user
        // has changed, so reset the state.
        completeReturn();
    }

    return null;
};

export default LoopInitialiser;
