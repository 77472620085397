// This is the production config
export default {
    contexts: [
        {
            country: 'Asia Pacific',
            code: 'INTL',
            routePrefix: false,
            region: 'Australia',
            grouping: 'Asia Pacific',
            language: 'en-au',
            hrefLang: 'en-au',
            shopifyStorefrontUrl: 'https://maapintl.maap.cc/',
            shopifyStorefrontToken: '500bc9fa57d0e0d1100dbbd63da079f9',
            shopifyStorefrontPreviewToken: 'eeab21645125a8712fe42debd98c25f9',
            shopifyImageUrl:
                'https://cdn.shopify.com/s/files/1/0510/7809/files/',
            defaultCurrencyCode: 'AUD',
            defaultCurrencySymbol: '$',
            defaultRegionAbbreviated: 'AU',
            default: true,
            defaultPageDescription: 'Maap Cycling Apparel in Australia',
            storefrontUrl: 'https://maap.cc/',
            defaultOpenGraphImage: '/images/ogimage.jpg',
            titlePostfix: ' - MAAP Cycling Apparel',
            algoliaProductIndex: 'shopify_intl_products',
            algoliaCollectionsIndex: 'shopify_intl_collections',
            algoliaArticlesIndex: 'shopify_intl_articles',
            algoliaPagesIndex: 'shopify_intl_pages',
            metaTitle: 'MAAP | Performance Cycling Apparel &amp; Cycling Kits',
            metaDescription:
                'MAAP is committed to developing the art and progression of cycling through a considered approach to style, innovation and performance. Shop Now.',
            metaKeywords: 'Cycling Apparel',
            gtmContainerID: 'GTM-MM7SMP',
            klaviyoCompanyID: 'an79Sm',
            justUNOID: 'E2B3CB0F-64E3-4DF9-9890-36197C1121A8',
            metaTitlePostfix: '| MAAP',
            collectionMetaDescriptionPostfix:
                'range available. Free Express Shipping over $100 AUD with MAAP',
            productMetaDescriptionPostfix:
                'available online. Free Express Shipping over $100 AUD with MAAP.',
            blogMetaDescriptionPrefix: 'Here is our write up on',
            blogMetaDescriptionPostfix: 'Read MAAP’s blog for more info.',
            icon: 'https://cdn.shopify.com/s/files/1/0510/7809/files/flag-australia.png',
            currency: 'AU',
            loopApiKey: '5993c78331f6f361e63199aa4c5fa0caa4153b80',
            googleSiteVerification:
                'GwPdUumeh7qa83JE_UgILinrDCl4d8lMkGqwxO9k42M',
            nostoAccountID: 'shopify-5107809',
            tiktokTrackingCode: 'CFQO3CBC77UBIS8PJ4MG',
            pinterestTagId: '2612528985582',
            pinterestContactEmail: 'info@maap.cc'
        },
        {
            country: 'America',
            code: 'US',
            routePrefix: 'us',
            region: 'United States',
            grouping: 'United States',
            language: 'en-us',
            hrefLang: 'en-us',
            countryName: [
                'United States',
                'Canada',
                'Mexico',
                'Rest of Americas',
            ],
            shopifyStorefrontUrl: 'https://maapus.maap.cc/',
            shopifyStorefrontToken: '30f853242eb81fc4de306a58e2f959d8',
            shopifyStorefrontPreviewToken: '86ac0dbc353ff489a1bbbcff98c1c51e',
            shopifyImageUrl:
                'https://cdn.shopify.com/s/files/1/0510/7809/files/',
            defaultCurrencyCode: 'USD',
            defaultCurrencySymbol: '$',
            defaultRegionAbbreviated: 'US',
            default: false,
            defaultPageDescription: 'Maap Cycling Apparel in the Americas',
            storefrontUrl: 'https://maap.cc/',
            defaultOpenGraphImage: '/images/ogimage.jpg',
            titlePostfix: ' - MAAP Cycling Apparel',
            algoliaProductIndex: 'shopify_us_production_products',
            algoliaCollectionsIndex: 'shopify_us_production_collections',
            algoliaArticlesIndex: 'shopify_intl_articles',
            algoliaPagesIndex: 'shopify_intl_pages',
            metaTitle: 'MAAP | Performance Cycling Apparel &amp; Cycling Kits',
            metaDescription:
                'MAAP is committed to developing the art and progression of cycling through a considered approach to style, innovation and performance. Shop Now.',
            metaKeywords: 'Cycling Apparel',
            gtmContainerID: 'GTM-P533ZR',
            klaviyoCompanyID: 'spuDKT',
            justUNOID: 'A75CCE1D-1B46-413A-A33D-6A775FF746F5',
            metaTitlePostfix: '| MAAP US',
            collectionMetaDescriptionPostfix:
                'range available. Free Express Shipping over $100 USD with MAAP.',
            productMetaDescriptionPostfix:
                'available online. Free Express Shipping over $100 USD with MAAP.',
            blogMetaDescriptionPrefix: 'Here is our write up on',
            blogMetaDescriptionPostfix: 'Read MAAP’s blog for more info.',
            icon: 'https://cdn.shopify.com/s/files/1/1431/8222/files/flag-united-states.png',
            currency: 'USD',
            loopApiKey: 'cba32d78e5d33b3c2d6678e671e15f4a1f3321a7',
            nostoAccountID: 'shopify-14318222',
        },
        {
            country: 'Europe',
            code: 'EU',
            routePrefix: 'eu',
            region: 'Europe',
            grouping: 'Europe',
            language: 'en-eu',
            hrefLang: 'en',
            shopifyStorefrontUrl: 'https://maapeu.maap.cc/',
            shopifyStorefrontToken: '84b0e23a57ca2ba6307896b2ad2e9f5a',
            shopifyStorefrontPreviewToken: 'b05abab25d89734991f7739138ac630c',
            shopifyImageUrl:
                'https://cdn.shopify.com/s/files/1/0510/7809/files/',
            defaultCurrencyCode: 'EUR',
            defaultCurrencySymbol: '€',
            defaultRegionAbbreviated: 'EU',
            default: false,
            defaultPageDescription: 'Maap Cycling Apparel in Europe',
            storefrontUrl: 'https://maap.cc/',
            defaultOpenGraphImage: '/images/ogimage.jpg',
            titlePostfix: ' - MAAP Cycling Apparel',
            algoliaProductIndex: 'shopify_eu_production_products',
            algoliaCollectionsIndex: 'shopify_eu_production_collections',
            algoliaArticlesIndex: 'shopify_intl_articles',
            algoliaPagesIndex: 'shopify_intl_pages',
            metaTitle: 'MAAP | Performance Cycling Apparel &amp; Cycling Kits',
            metaDescription:
                'MAAP is committed to developing the art and progression of cycling through a considered approach to style, innovation and performance. Shop Now.',
            metaKeywords: 'Cycling Apparel',
            gtmContainerID: 'GTM-58N9VRS',
            klaviyoCompanyID: 'K5Bis4',
            justUNOID: '0E16CF97-28B4-4576-AB4F-48FF09D14C38',
            metaTitlePostfix: '| MAAP EU',
            collectionMetaDescriptionPostfix:
                'range available. Free Express Shipping over €100 EURO with MAAP.',
            productMetaDescriptionPostfix:
                'available online. Free Express Shipping over €100 EURO with MAAP.',
            blogMetaDescriptionPrefix: 'Here is our write up on',
            blogMetaDescriptionPostfix: 'Read MAAP’s blog for more info.',
            icon: 'https://cdn.shopify.com/s/files/1/2180/3833/files/flag-europe.png',
            currency: 'EURO',
            loopApiKey: '7323e4a3c1e5165077876f377d05b83509549c93',
            googleSiteVerification:
                'GwPdUumeh7qa83JE_UgILinrDCl4d8lMkGqwxO9k42M',
            nostoAccountID: 'shopify-21803833',
        },
        {
            country: 'United Kingdom',
            code: 'UK',
            routePrefix: 'uk',
            grouping: 'Europe',
            region: 'United Kingdom',
            language: 'en-uk',
            hrefLang: 'en-gb',
            shopifyStorefrontUrl: 'https://maapuk.maap.cc/',
            shopifyStorefrontToken: '1281400f87990117c7e16ed5e7636c83',
            shopifyStorefrontPreviewToken: 'ac1db09d8e23c8791f31ad69dac127b1',
            shopifyImageUrl:
                'https://cdn.shopify.com/s/files/1/0570/5446/2121/files/',
            defaultCurrencyCode: 'GBP',
            defaultCurrencySymbol: '£',
            defaultRegionAbbreviated: 'UK',
            default: false,
            defaultPageDescription: 'Maap Cycling Apparel in United Kingdom',
            storefrontUrl: 'https://maap.cc/',
            defaultOpenGraphImage: '/images/ogimage.jpg',
            titlePostfix: ' - MAAP Cycling Apparel',
            algoliaProductIndex: 'shopify_uk_production_products',
            algoliaCollectionsIndex: 'shopify_uk_collections',
            algoliaArticlesIndex: 'shopify_intl_articles',
            algoliaPagesIndex: 'shopify_intl_pages',
            metaTitle: 'MAAP | Performance Cycling Apparel &amp; Cycling Kits',
            metaDescription:
                'MAAP is committed to developing the art and progression of cycling through a considered approach to style, innovation and performance. Shop Now.',
            metaKeywords: 'Cycling Apparel',
            gtmContainerID: 'GTM-N9BKK9N',
            klaviyoCompanyID: 'YwMLxd',
            justUNOID: 'BD0B9D97-5533-408C-860B-5C1ED134DD93',
            metaTitlePostfix: '| MAAP UK',
            collectionMetaDescriptionPostfix:
                'range available. Free Express Shipping over £100 GBP with MAAP.',
            productMetaDescriptionPostfix:
                'available online. Free Express Shipping over £100 GBP with MAAP.',
            blogMetaDescriptionPrefix: 'Here is our write up on',
            blogMetaDescriptionPostfix: 'Read MAAP’s blog for more info.',
            icon: 'https://cdn.shopify.com/s/files/1/0570/5446/2121/files/flag-united-kingdom.png',
            currency: 'GBP',
            loopApiKey: 'c99106a716c8b9ab07940280a6124ea570c77082',
            googleSiteVerification:
                'GwPdUumeh7qa83JE_UgILinrDCl4d8lMkGqwxO9k42M',
            nostoAccountID: 'shopify-57054462121',
        },
        {
            country: 'International',
            code: 'INTL',
            subCountry: 'Rest of World',
            region: 'Rest of World',
            overridesRegion: 'Australia',
            grouping: 'International',
            defaultCurrencyCode: 'AUD',
            defaultCurrencySymbol: '$',
            defaultRegionAbbreviated: 'ROW',
            default: false,
            icon: 'https://cdn.shopify.com/s/files/1/0510/7809/files/flag-rest-of-world.png',
        },
    ],
    breakPoints: {
        smallMobile: { maxDeviceWidth: 360 },
        mobile: { maxDeviceWidth: 767 },
        tablet: { minDeviceWidth: 768, maxDeviceWidth: 991 },
        desktop: { minDeviceWidth: 992, largeDeviceWidth: 1440 },
        mobileDeviceWidth: { deviceWidth: 767 },
        tabletDeviceWidth: { deviceWidth: 991 },
    },
    services: {
        prismic: {
            repositoryName: 'maap',
            repositoryUrl: 'https://maap.cdn.prismic.io/api/v2',
            accessToken:
                'MC5YZ1c3SXhJQUFDSUFQRGMz.77-977-977-977-977-977-9F--_vUDvv73vv70k77-977-977-9KFvvv711a--_ve-_vRbvv70JNe-_vU_vv71N77-9GA',
        },
        algolia: {
            applicationID: '9KZ3AQR8PN',
            apiKey: '0fce7a190e07c0e23b86cffd188fbc2e',
        },
    },
    navigation: {
        desktop: [
            {
                title: 'Man',
                hasSubNav: true,
                linkKey: 'group_link_man',
                subNavKey: 'man_sub_navigation',
            },
            {
                title: 'Woman',
                hasSubNav: true,
                linkKey: 'group_link_woman',
                subNavKey: 'woman_sub_navigation',
            },
            {
                title: 'Accessories',
                hasSubNav: true,
                linkKey: 'group_link_accessories',
                subNavKey: 'accessories_sub_navigation',
            },
            {
                title: 'Archive Sale',
                hasSubNav: true,
                linkKey: 'group_link_archive_sale',
                subNavKey: 'archive_sale_sub_navigation',
            },
            {
                title: 'Stories',
                hasSubNav: true,
                linkKey: 'group_link_stories',
                subNavKey: 'stories_navigation',
                regionless: true,
            },
            // {
            //     title: 'Stores',
            //     hasSubNav: false,
            //     link: '/pages/stores',
            // },
        ],
        mobile: {
            tabs: [
                {
                    title: 'Man',
                    hasSubNav: true,
                    linkKey: 'group_link_man',
                    subNavKey: 'man_sub_navigation',
                },
                {
                    title: 'Woman',
                    hasSubNav: true,
                    linkKey: 'group_link_woman',
                    subNavKey: 'woman_sub_navigation',
                },
                {
                    title: 'Accessories',
                    hasSubNav: true,
                    linkKey: 'group_link_accessories',
                    subNavKey: 'accessories_sub_navigation',
                },
                {
                    title: 'Sale',
                    hasSubNav: true,
                    linkKey: 'group_link_archive_sale',
                    subNavKey: 'archive_sale_sub_navigation',
                },
            ],
            others: [
                {
                    title: 'Stores',
                    hasSubNav: false,
                    link: '/pages/stores',
                },
            ],
        },
    },
    tracking: {
        brand: 'MAAP',
        category: 'Apparel & Accessories',
    },
    defaultLanguage: 'en-au',
    zendeskKey: '88e48c99-2339-48c8-86f2-45da47af6e2f',
};
