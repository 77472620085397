'use client';

import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import cn from 'clsx';
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerPanel,
} from '@app/components/ui/drawer/Drawer';
import s from './cart-sidebar.module.css';
import { WishlistLineProps } from 'mmds';
import useUI from '@app/components/ui/state';
import useCart from '@app/[lang]/cart/state';
import useWishlist from '@app/lib/wishlist/state';
import getFeatureFlag from '@app/lib/features/get-feature-flag';
import useDrawerDirection from '@app/lib/hooks/use-drawer-direction';
import CartTracking from '@app/[lang]/cart/cart-tracking/cart-tracking';
import CartLineItems from '@app/[lang]/cart/cart-line-items/cart-line-items';
import NostoRecommendationsBlock from '@app/components/nosto/nosto-recommendations-block';
import CartSidebarCartFooter from '@app/[lang]/cart/cart-sidebar-cart-footer/cart-sidebar-cart-footer';
import CartSidebarWishlistFooter from '@app/[lang]/cart/cart-sidebar-wishlist-footer/cart-sidebar-wishlist-footer';

const CartWishlistLineItems = dynamic(
    () =>
        import(
            '@app/[lang]/cart/cart-wishlist-line-items/cart-wishlist-line-items'
        ),
    {
        ssr: false,
    }
);

const CartSidebar = ({ gift }) => {
    const [wishlistLineItems, setWishlistLineItems] = useState<
        WishlistLineProps[]
    >([]);
    const { cart, cartItemsCount } = useCart();
    const { itemCount } = useWishlist();
    const isWishlistFeatureEnabled = getFeatureFlag('wishlist');
    const drawerDirection = useDrawerDirection();
    const { openUIKey, toggleUI } = useUI();
    const isDrawerOpen = openUIKey === 'cart' || openUIKey === 'wishlist';
    const showCartComponents = cart && cartItemsCount && openUIKey === 'cart';
    const showWishlistComponents = itemCount && openUIKey === 'wishlist';
    const showNostoRecentlyViewed = cartItemsCount === 0;

    return (
        <Drawer open={isDrawerOpen} onOpenChange={() => toggleUI(null)}>
            <DrawerPanel inFrom={drawerDirection} variant="cart-nav">
                <DrawerHeader
                    className="mmds-copy-three"
                    closeButtonVariant="text"
                >
                    <nav className={s.tabs}>
                        <h2
                            className={cn(
                                { [s.active]: openUIKey === 'cart' },
                                s.tab,
                                'mmds-title-five'
                            )}
                            onClick={() => toggleUI('cart')}
                        >
                            Bag{' '}
                            <div className="mmds-copy-three-detail">
                                {cartItemsCount}
                            </div>
                        </h2>
                        {isWishlistFeatureEnabled && (
                            <h2
                                className={cn(
                                    { [s.active]: openUIKey === 'wishlist' },
                                    s.tab,
                                    'mmds-title-five'
                                )}
                                onClick={() => {
                                    toggleUI('wishlist');
                                }}
                            >
                                Wishlist{' '}
                                <div className="mmds-copy-three-detail">
                                    {itemCount}
                                </div>
                            </h2>
                        )}
                    </nav>
                </DrawerHeader>
                <DrawerBody className={s.compatDrawer}>
                    {openUIKey === 'cart' && !cartItemsCount && (
                        <p className="mmds-subtitle-two">Your bag is empty</p>
                    )}
                    {!!showCartComponents && (
                        <>
                            <CartLineItems
                                cart={cart}
                                compact={true}
                                gift={gift}
                            />
                            <CartTracking cart={cart} />
                        </>
                    )}
                    {openUIKey === 'wishlist' && !itemCount && (
                        <p className="mmds-copy-three-detail">
                            Your wishlist is empty
                        </p>
                    )}
                    {!!showWishlistComponents && (
                        <CartWishlistLineItems
                            wishlistLineItems={wishlistLineItems}
                            setWishlistLineItems={setWishlistLineItems}
                            compact
                        />
                    )}
                </DrawerBody>
                {showNostoRecentlyViewed && (
                    <div>
                        <NostoRecommendationsBlock
                            placementIdentifier="cartpage-nosto-5"
                            pageKey={`cart_empty_state`}
                            id={'cart'}
                            type="product"
                            swiperType="product_rec_card"
                        />
                    </div>
                )}
                {!showNostoRecentlyViewed && (
                    <div>
                        <NostoRecommendationsBlock
                            placementIdentifier="cartpage-nosto-6"
                            pageKey={`cart_empty_state`}
                            id={'cart'}
                            type="product"
                            swiperType="product_rec_card"
                        />
                    </div>
                )}
                <DrawerFooter className={cn(s.zeroPadding)}>
                    {!!showCartComponents && (
                        <CartSidebarCartFooter cart={cart} gift={gift} />
                    )}
                    {!!showWishlistComponents && <CartSidebarWishlistFooter />}
                </DrawerFooter>
            </DrawerPanel>
        </Drawer>
    );
};

export default CartSidebar;
