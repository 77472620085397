'use client';

import React, { useState, useEffect, memo } from 'react';
import setIsDismissedCookieValue from '@app/components/ui/utils/set-is-dissmissed-cookie-value';
import getIsDismissedCookieValue from '@app/components/ui/utils/get-is-dissmissed-cookie-value';

export function withDismissible<T>(
    Component: React.ComponentType<T>,
    name: string
) {
    return memo(function DismissibleChild(
        props: Omit<T, 'dismiss' | 'dismissed' | 'transition'>
    ) {
        const [isDismissed, setIsDismissed] = useState<boolean>(true);
        const [transition, setTransition] = useState<null | 'in' | 'out'>(null);

        useEffect(() => {
            if (!getIsDismissedCookieValue(name)) {
                setTransition('in');
                setIsDismissed(false);
                setTimeout(() => {
                    setTransition(null);
                }, 1000);
            }
        }, []);
        const dismiss = (name: string) => {
            setTransition('out');
            setTimeout(() => {
                setIsDismissed(true);
                setTransition(null);
                setIsDismissedCookieValue(name);
            }, 1000);
        };

        return (
            <Component
                {...(props as T)}
                dismiss={dismiss}
                dismissed={isDismissed}
                transition={transition}
            />
        );
    });
}

export default withDismissible;
