export default () => {
    if (typeof document === 'undefined') return null;
    const cookie = document.cookie
        .split('; ')
        .find((cookie) => cookie.startsWith('_im_clickid'));
    if (!cookie) {
        return null;
    }
    return cookie.replace(`${cookie.split('=')[0]}=`, '');
};
