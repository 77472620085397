import React from 'react';
import { useParams, useRouter } from 'next/navigation';
import { Button } from 'mmds';
import CartCompleteBundleNotice from '@app/[lang]/cart/cart-complete-bundle-notice/cart-complete-bundle-notice';
import CartGiftNotice from '../cart-gift-notice/cart-gift-notice';
import CartCallout from '@app/[lang]/cart/cart-callout/cart-callout';
import { cartContainsGifts } from '@app/lib/helpers/cart-contains-gifts';
import Cart from '@app/lib/types/Cart';
import useUI from '@app/components/ui/state';
import localisePath from '@app/lib/helpers/localise-path';
import useLoopReturns from '@app/lib/loop-returns/state';
import { Language } from '@app/lib/store-context/Context';
import s from './cart-sidebar-cart-footer.module.css';

interface CartSidebarCartFooterProps {
    cart: Cart;
    gift?: any;
    countryCode?: string;
}

const CartSidebarCartFooter = ({ cart, gift }: CartSidebarCartFooterProps) => {
    const { toggleUI } = useUI();
    const { push } = useRouter();
    const { lang } = useParams();
    const { isCreditShoppingActive, availableCredit } = useLoopReturns(
        (state) => ({
            isCreditShoppingActive: state.isCreditShoppingActive,
            availableCredit: state.availableCredit,
        })
    );
    const hasGifts = cartContainsGifts(cart);

    const containsHelmet = (cart: Cart) => {
        return cart.lines.edges.some((edge) => {
            return edge.node.merchandise?.product?.productType === 'Helmet';
        });
    };
    const hasHelmet = containsHelmet(cart);
    const priceFormatter = new Intl.NumberFormat(lang || 'en-AU', {
        style: 'currency',
        currency: cart?.cost.totalAmount.currencyCode || 'AUD',
        currencyDisplay: 'narrowSymbol',
    });

    let cartTotal = cart?.cost.totalAmount.amount;
    if (isCreditShoppingActive && availableCredit !== undefined) {
        cartTotal = (Number(cartTotal) - availableCredit).toString();
    }

    return (
        <div className={s.root}>
            {hasHelmet && (
                <CartCallout
                    heading="Attention"
                    text="Please ensure the safety standards of the MAAP x KASK Protone Icon Helmet is suitable for your region."
                />
            )}
            {hasGifts && <CartGiftNotice gift={gift} />}
            {!isCreditShoppingActive && (
                <CartCompleteBundleNotice lineItemConnection={cart.lines} />
            )}
            <div className={s.summary}>
                <div className="mmds-component-one-detail">
                    Total:{' '}
                    {cart.discountCodes?.[0]?.applicable ? (
                        <span className={s.totalStrikethrough}>
                            {priceFormatter.format(
                                Number(cart.cost.subtotalAmount.amount)
                            )}{' '}
                        </span>
                    ): null}
                    {isCreditShoppingActive ? (
                        <span className={s.totalStrikethrough}>
                            {priceFormatter.format(
                                Number(cart.cost.totalAmount.amount)
                            )}{' '}
                            {cart.cost.totalAmount.currencyCode}
                        </span>
                    ) : (
                        <span>
                            {priceFormatter.format(Number(cartTotal))}{' '}
                            {cart.cost.totalAmount.currencyCode}
                        </span>
                    )}
                </div>
                <div className={s.button}>
                    <Button
                        label="View Bag"
                        onClick={() => {
                            push(localisePath('/cart', lang as Language));
                            toggleUI('cart');
                        }}
                        radiusSize="none"
                    />
                </div>
            </div>
        </div>
    );
};

export default CartSidebarCartFooter;
