import Cart from '@app/lib/types/Cart';
import { AttributeInput } from 'shopify-storefront-api-typings';
import { getClient } from '@app/lib/shopify';
import { cartLinesAdd } from '@app/lib/shopify/mutations/cart-lines-add';
import { cartCreate } from '@app/lib/shopify/mutations/cart-create';
import { ShopifyClientContext } from '@app/lib/shopify/client';
import { captureMessage } from '@sentry/nextjs';
import { UserError } from 'shopify-storefront-api-typings';

interface CartLineItemInput {
    merchandiseId: string;
    quantity: number;
    attributes?: AttributeInput[];
}

interface CartInput {
    lines: CartLineItemInput[];
    attributes?: AttributeInput[];
}

export default async (
    clientSettings: ShopifyClientContext,
    cartId: string | null,
    lineItems: CartLineItemInput[],
    cartCustomAttributes?: AttributeInput[] // These are cart, not lineItem attributes.
): Promise<{ cart: Cart | null; userErrors: UserError[] }> => {
    try {
        let mutation = cartCreate;
        let mutationVariables:
            | { input: CartInput }
            | {
                  cartId: string;
                  lines: CartLineItemInput[];
                  attributes?: AttributeInput[];
              };

        if (cartId) {
            mutation = cartLinesAdd;
            mutationVariables = {
                cartId,
                lines: lineItems,
                attributes: cartCustomAttributes,
            };
        } else {
            mutationVariables = {
                input: {
                    lines: lineItems,
                    attributes: cartCustomAttributes,
                },
            };
        }
   
        const cartResponse = await getClient(clientSettings).request.send({
            query: mutation,
            variables: {
                country: clientSettings.shopifyMarketCountryCode,
                ...mutationVariables,
                next: { cache: 'nocache' },
            },
        });

        return {
            cart:
                cartResponse?.cartCreate?.cart ||
                cartResponse?.cartLinesAdd?.cart ||
                null,
            userErrors:
                cartResponse?.cartCreate?.userErrors ||
                cartResponse?.cartLinesAdd?.userErrors ||
                [],
        };
    } catch (e) {
        const message = `Could not add items to cart`;
        captureMessage(message);
        console.warn(message, e);
        return { cart: null, userErrors: [{ message: e.message }] };
    }
};
