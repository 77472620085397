import { gql } from 'graphql-request';
import { CART_FIELDS } from '@app/lib/shopify/queries/fragments/cart-fields';

export const cartLinesRemove = gql`
    mutation cartLinesRemove(
        $country: CountryCode
        $cartId: ID!
        $lineIds: [ID!]!
    ) @inContext(country: $country) {
        cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
            userErrors {
                message
                field
            }
            cart {
                ...cartFields
            }
        }
    }
    ${CART_FIELDS}
`;
