'use client';

import { contentStructureFooterNavItem, FooterNavProps } from './types';
import { useState } from 'react';
import { NavItem } from 'mmds';
import {
    ContentStructure,
    useContentStructure,
} from '@app/lib/content-structure';
import NewsletterSubscriptionForm from './NewsletterSubscriptionForm';
import { Language } from '@app/lib/store-context/Context';
import { useParams } from 'next/navigation';
import Linker from '@app/components/linker/linker';
import cn from 'clsx';
import s from './FooterNav.module.css';

const FooterNavMobileVariant = ({ items, activeItem }: FooterNavProps) => {
    const { lang } = useParams();
    const { structure, assertLoseNodeMatch } = useContentStructure(items);
    const [openCluster, setOpenCluster] = useState<null | string>(null);
    const [footerItems, setFooterItems] = useState<ContentStructure>(structure);

    return (
        <section className={cn(s.root, s.mobile)}>
            <NewsletterSubscriptionForm />
            <div className={s.divider} />
            <div className={s.navContainer}>
                <div className={s.navItems}>
                    {footerItems.map(
                        (item: contentStructureFooterNavItem, key) => {
                            if (item.children) {
                                return (
                                    <NavItem
                                        key={key}
                                        variant="l1"
                                        label={item.label}
                                        onSelect={() =>
                                            setOpenCluster(item.label)
                                        }
                                        open={openCluster === item.label}
                                        icon="down"
                                        openCluster={openCluster}
                                        className={s.navItem}
                                        type="footer"
                                        lang={lang as Language}
                                        cluster={item.children.map(
                                            (
                                                child: contentStructureFooterNavItem
                                            ) => {
                                                let active = false;
                                                if (activeItem) {
                                                    active =
                                                        assertLoseNodeMatch(
                                                            child,
                                                            activeItem
                                                        );
                                                }
                                                return {
                                                    active,
                                                    label: child.label,
                                                    hrefLink: child.hrefLink,
                                                    target: child.target,
                                                    componentType: 'button',
                                                    buttonVariant: 'quite',
                                                    padding: 'zeroInline',
                                                };
                                            }
                                        )}
                                    />
                                );
                            }

                            return (
                                <Linker
                                    key={`${item.hrefLink}-${item.label}`}
                                    href={item.hrefLink || null}
                                    title={`Go to ${item.label}`}
                                    passthrough={!item.hrefLink}
                                    target={item.target}
                                    lang={lang as Language}
                                >
                                    <NavItem
                                        variant="l1"
                                        label={item.label}
                                        icon="northeast"
                                        hrefLink={item.hrefLink}
                                        type="footer"
                                    />
                                </Linker>
                            );
                        }
                    )}
                </div>
            </div>
        </section>
    );
};

export default FooterNavMobileVariant;
