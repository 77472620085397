import { useEffect, useRef } from 'react';
import Cart from '@app/lib/types/Cart';
import useCart from '@app/[lang]/cart/state';
import addLinesToCart from '@app/lib/shopify/methods/add-lines-to-cart';
import { useParams } from 'next/navigation';
import { getShopifyClientSettings } from '@app/lib/shopify/get-client-settings';
import { Language } from '@app/lib/store-context/Context';
import removeFromCart from '@app/lib/shopify/methods/remove-from-cart';

const useManageGift = (gift) => {
    const { lang } = useParams();
    const { cart, setCart } = useCart();
    const clientSettings = getShopifyClientSettings(lang as Language);
    const didTryToAddGift = useRef<boolean>(false);
    let giftVariantGID: string | null = null;

    if (gift) {
        giftVariantGID = `gid://shopify/ProductVariant/${gift.variantID}`;
    }

    useEffect(() => {
        const isCartEligibleForGift = (cart: Cart) => {
            // If no gift present, cart is not eligible.
            // Someone may have turned off the gift slice and it should be removed from carts.
            if (!gift) return false;

            // If the cart value is less than a dollar (empty), it's not eligible.
            if (cart.cost.subtotalAmount.amount < 1) return false;

            const hasEligibleSubtotal =
                cart.cost.subtotalAmount.amount >= gift.cartValueRequirement;
            const hasEligibleItems = cart.lines.edges.some(
                (edge) =>
                    edge.node?.merchandise?.product.productType !==
                        'MAGAZINE' &&
                    edge.node?.merchandise?.product.productType !== 'Gift Card'
            );
            return hasEligibleSubtotal && hasEligibleItems;
        };

        const getGiftLineItemID = (cart: Cart) => {
            const lineItem = cart.lines.edges.find(
                (edge) => edge.node?.merchandise?.id === giftVariantGID
            );
            return lineItem?.node.id;
        };

        const isGiftInCart = (cart: Cart) => {
            return cart.lines.edges.some(
                (edge) => edge.node?.merchandise?.id === giftVariantGID
            );
        };

        const isGiftAlreadyInCartOrUnwanted = (cart: Cart) => {
            if (!gift) return false;
            const isAlreadyInCart = isGiftInCart(cart);

            const unwantedId = sessionStorage.getItem(
                'GIFT_UNWANTED_VARIANT_ID'
            );
            console.log('Unwanted ID from session storage:', unwantedId);

            let isUnwanted = false;
            if (unwantedId) {
                isUnwanted = unwantedId === giftVariantGID;
            }

            return isAlreadyInCart || isUnwanted;
        };

        const addGiftToCart = async () => {
            if (!giftVariantGID || !cart) return;

            const giftLineItem = [
                {
                    merchandiseId: giftVariantGID,
                    quantity: 1,
                    attributes: [{ key: 'gift', value: 'true' }],
                },
            ];

            // If gift is in the cart, but the cart is ineligible, remove the gift
            if (!isCartEligibleForGift(cart) && isGiftInCart(cart)) {
                const giftLineItem = getGiftLineItemID(cart);
                if (giftLineItem) {
                    const updatedCart = await removeFromCart(
                        cart.id,
                        [giftLineItem],
                        clientSettings
                    );
                    if (updatedCart) {
                        setCart(updatedCart);
                    }
                }
            } else if (
                !isGiftAlreadyInCartOrUnwanted(cart) &&
                isCartEligibleForGift(cart),
                !didTryToAddGift.current // Prevent possible infinite loop when gift variant is not available.
            ) {
                const { cart: updatedCart } = await addLinesToCart(
                    clientSettings,
                    cart.id,
                    giftLineItem
                );
                if (updatedCart) {
                    setCart(updatedCart);
                    didTryToAddGift.current = true;
                }
            }
        };

        addGiftToCart();
    }, [cart, gift, lang, setCart, clientSettings, giftVariantGID]);
};

export default useManageGift;
