'use client';

import React, { useEffect, useState } from 'react';
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerPanel,
} from '@app/components/ui/drawer/Drawer';
import useUI from '@app/components/ui/state';
import { Button, Input } from 'mmds';
import { useParams, useSearchParams } from 'next/navigation';
import s from './password-reset-sidebar.module.css';

export const PasswordResetSidebar = () => {
    const { openUIKey, toggleUI } = useUI();
    const [email, setEmail] = useState<string | null>(null);
    const [message, setMessage] = useState<string | null>(null);
    const [status, setStatus] = useState<number | null>(null);
    const isDrawerOpen = openUIKey === 'password-reset';
    const searchParams = useSearchParams();
    const resetPasswordParam = searchParams.get('password_reset');
    const { lang } = useParams();

    useEffect(() => {
        if (resetPasswordParam) {
            toggleUI('password-reset');
        }
    }, [resetPasswordParam]);

    const handleResetPassword = async () => {
        // Send email to reset password
        fetch('/api/account/reset-password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, lang }),
        })
            .then((result) => {
                setStatus(result.status);
                if (result.status === 200) {
                    setMessage(
                        'If that email address is associated with an account, you will receive an email with a link to reset your password.'
                    );
                } else {
                    setMessage(
                        'Password reset failed. Please check your email and try again.'
                    );
                }
            })
            .catch((error) => {
                setStatus(500);
                setMessage(
                    'Password reset failed. Please check your email and try again in a few minutes.'
                );
            });
    };

    return (
        <Drawer open={isDrawerOpen} onOpenChange={() => toggleUI('profile')}>
            <DrawerPanel inFrom="right" variant="catalogue-nav">
                <DrawerHeader
                    className="mmds-copy-three"
                    closeButtonVariant="text"
                >
                    <div className={s.header}>Password Reset</div>
                </DrawerHeader>
                <DrawerBody>
                    <div className={s.resetForm}>
                        {message && <div className={s.message}>{message}</div>}
                        {status !== 200 && (
                            <div className={s.inputField}>
                                <Input
                                    labelText="Email"
                                    onChange={(value) => setEmail(value)}
                                    type="email"
                                    id="name"
                                    name="name"
                                    placeholder="Your email..."
                                    required
                                />
                            </div>
                        )}
                        {status !== 200 && (
                            <div className={s.buttonField}>
                                <Button
                                    disabled={!email}
                                    onClick={handleResetPassword}
                                >
                                    Send reset email
                                </Button>
                            </div>
                        )}
                    </div>
                </DrawerBody>
                <DrawerFooter>
                    <div className={s.button}>
                        <Button
                            variant="secondary"
                            label="Close"
                            onClick={() => {
                                toggleUI('password-reset');
                                setMessage(null);
                                setEmail(null);
                            }}
                            radiusSize="none"
                        />
                    </div>
                </DrawerFooter>
            </DrawerPanel>
        </Drawer>
    );
};

export default PasswordResetSidebar;
