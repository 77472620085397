import { UserError } from 'shopify-storefront-api-typings';

type SetErrorMessage = (field: string, message: string | null) => void;

export const handleUserErrors = (
    userErrors: UserError[],
    setErrorMessage: SetErrorMessage
) => {
    userErrors.forEach((error) => {
        if (error.field) {
            const fieldKey = error.field.join('.');
            console.log(`Error in field ${fieldKey}: ${error.message}`);
            setErrorMessage(fieldKey, error.message);
        } else {
            console.log(`Unknown error: ${error.message}`);
            setErrorMessage('unknown', error.message);
        }
    });
};
