import Cart from '@app/lib/types/Cart';
import { getClient } from '@app/lib/shopify';
import { cartLinesRemove } from '../mutations/cart-lines-remove';
import { ShopifyClientContext } from '@app/lib/shopify/client';
import { captureMessage } from '@sentry/nextjs';

const removeLineItems = async (
    cartId: string | null,
    lineIds: string[] | null,
    clientSettings: ShopifyClientContext
): Promise<Cart | null> => {
    try {
        const cartResponse = await getClient(clientSettings).request.send({
            query: cartLinesRemove,
            variables: {
                country: clientSettings.shopifyMarketCountryCode,
                cartId,
                lineIds,
                next: { cache: 'nocache' },
            },
        });
        return cartResponse?.cartLinesRemove?.cart || null;
    } catch (e) {
        const message = `Could not remove item(s) from cart: ${lineIds?.join(
            ','
        )}`;
        captureMessage(message);
        console.warn(message, e);
    }
    return null;
};

export default removeLineItems;
