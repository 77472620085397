import React from 'react';
import Cart from '@app/lib/types/Cart';
import CartLineItemEdge from '@app/lib/types/CartLineItemEdge';
import CartLineItem from '@app/[lang]/cart/cart-line-item/cart-line-item';
import { getShopifyClientSettings } from '@app/lib/shopify/get-client-settings';
import { Language } from '@app/lib/store-context/Context';
import Divider from '@app/components/ui/divider/divider';
import { useParams } from 'next/navigation';
import useLoopReturns from '@app/lib/loop-returns/state';
import cn from 'clsx';
import s from './cart-line-items.module.css';

const getBundleId = (lineItemNode: CartLineItemEdge['node']): string | null => {
    return (
        lineItemNode.attributes.find(
            (attribute) => attribute.key === '_bundle_id'
        )?.value || null
    );
};

interface CartLineItemsProps {
    cart: Cart;
    compact?: boolean;
    gift?: any;
}

export default function CartLineItems({
    cart,
    compact = false,
    gift,
}: CartLineItemsProps) {
    const { lang } = useParams();
    const clientSettings = getShopifyClientSettings(lang as Language);
    const { isCreditShoppingActive } = useLoopReturns((state) => ({
        isCreditShoppingActive: state.isCreditShoppingActive,
    }));
    const lineItemEdges: CartLineItemEdge[] = cart?.lines?.edges || [];
    const priceFormatter = new Intl.NumberFormat(lang, {
        style: 'currency',
        currency: cart?.cost.totalAmount.currencyCode,
        currencyDisplay: 'narrowSymbol',
    });
    lineItemEdges.sort((a, b) => {
        const aBundleId = getBundleId(a.node);
        const bBundleId = getBundleId(b.node);
        if (aBundleId && bBundleId) {
            return aBundleId.localeCompare(bBundleId);
        }
        return 0;
    });

    return (
        <div className={cn(s.root, { [s.compact]: compact })}>
            {lineItemEdges.map((edge, index) => (
                <div key={`cart_item_${index}`}>
                    <CartLineItem
                        key={`line_${edge.node.id}`}
                        lineItemEdge={edge}
                        priceFormatter={priceFormatter}
                        clientSettings={clientSettings}
                        compact={compact}
                        lang={lang as Language}
                        gift={gift}
                        isCreditShoppingActive={isCreditShoppingActive}
                    />
                    <Divider key={`divider_${edge.node.id}`} />
                </div>
            ))}
        </div>
    );
}
