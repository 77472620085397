'use client';

import React, { useEffect } from 'react';
import { Drawer, DrawerPanel } from '@app/components/ui/drawer/Drawer';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import useUI from '@app/components/ui/state';
import ProfilePortal from './profile-portal';

export const ProfilePortalSidebar = () => {
    const { openUIKey, toggleUI } = useUI();
    const pathname = usePathname();
    const router = useRouter();
    const searchParams = useSearchParams();
    const isDrawerOpen = openUIKey === 'profile';
    const queryToken = searchParams.get('token');
    const queryOpenProfileUI = searchParams.get('open_profile_ui');

    // Open the sidebar when returning from redirect with token.
    useEffect(() => {
        // This request is either a post local signin using portal sidebar,
        // or the customer clicked the login link in the checkout.

        // See app/[lang]/page for the server side redirect when arriving from profileportal.
        if (queryToken || queryOpenProfileUI) {
            if (openUIKey !== 'profile') {
                toggleUI('profile');
            }
        }
        if (queryOpenProfileUI) {
            const cleanedSearchParams = new URLSearchParams(
                searchParams.toString()
            );
            cleanedSearchParams.delete('open_profile_ui');
            router.replace(`${pathname}?${cleanedSearchParams}`);
        }
    }, [queryToken, queryOpenProfileUI]);

    // https://nextjs.org/docs/messages/deopted-into-client-rendering
    return (
        <Drawer open={isDrawerOpen} onOpenChange={() => toggleUI('profile')}>
            <DrawerPanel inFrom="right" variant="catalogue-nav">
                <ProfilePortal toggleUI={() => toggleUI('profile')}/>
            </DrawerPanel>
        </Drawer>
    );
};

export default ProfilePortalSidebar;
