import { Language } from '@app/lib/store-context/Context';
import {
    FlagAU,
    FlagEU,
    FlagHK,
    FlagKOR,
    FlagROW,
    FlagSG,
    FlagTW,
    FlagUK,
    FlagUS,
} from 'mmds';
const LangFlag = ({
    lang,
    className,
}: {
    lang: Language | 'en-KOR';
    className?: string;
}) => {
    const flags = {
        'en-AU': FlagAU,
        'en-SG': FlagSG,
        'en-EU': FlagEU,
        'en-US': FlagUS,
        'en-UK': FlagUK,
        'en-ROW': FlagROW,
        'en-KOR': FlagKOR,
        'en-TW': FlagROW,
        'en-HK': FlagHK,
    };
    const language = lang || 'en-ROW';
    const Flag = flags[language];
    return <Flag className={className} />;
};

export default LangFlag;
