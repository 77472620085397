import { gql } from 'graphql-request';
import { CART_FIELDS } from '../queries/fragments/cart-fields';

export const cartDiscountCodesUpdate = gql`
    mutation cartDiscountCodesUpdate(
        $country: CountryCode
        $cartId: ID!
        $discountCodes: [String!]
    ) @inContext(country: $country) {
        cartDiscountCodesUpdate(
            cartId: $cartId
            discountCodes: $discountCodes
        ) {
            userErrors {
                field
                message
            }
            cart {
                ...cartFields
            }
        }
    }
    ${CART_FIELDS}
`;
