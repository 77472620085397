import {
    ContentStructure,
    ContentStructureNavItem,
    UseContentStructureProvides,
} from './content-structure';
import { inferGenderFromHandle } from '@app/lib/content-structure/infer-gender';

// Lose matcher because we may not always have all the properties
// when calling one of the functions such as getNavFromNode
const assertLoseNodeMatch = (
    testNode: ContentStructureNavItem,
    structureNode: ContentStructureNavItem
) => {
    // If they are available, match on label and gender first.
    if (testNode.label && testNode.gender) {
        return (
            testNode.label === structureNode.label &&
            testNode.gender === structureNode.gender
        );
    }

    // If not available, infer the gender from HREF and do the same thing.
    if (testNode.hrefLink && testNode.label) {
        const inferredGender = inferGenderFromHandle(testNode.hrefLink);
        return (
            testNode.label === structureNode.label &&
            inferredGender === structureNode.gender
        );
    }

    if (testNode.hrefLink && testNode.label) {
        const inferredGender = inferGenderFromHandle(testNode.hrefLink);
        return (
            testNode.label === structureNode.label &&
            inferredGender === structureNode.gender
        );
    }

    // If that can't happen for some reason, use HREFS.
    if (testNode.hrefLink && structureNode.hrefLink) {
        if (
            testNode.hrefLink === structureNode.hrefLink ||
            testNode.hrefLink.replace('/collections/', '/ncollections/') ===
                structureNode.hrefLink
        ) {
            return true;
        }
    }

    // Otherwise it failed.
    return false;
};

export function useContentStructure(
    structure: ContentStructure
): UseContentStructureProvides {
    const getNavFromNode = (
        node: ContentStructureNavItem | null = null
    ): ContentStructureNavItem[] | null => {
        if (!node) {
            return structure;
        }

        for (let i = 0; i < structure.length; i++) {
            const tier1Item = structure[i];
            if (assertLoseNodeMatch(tier1Item, node)) {
                return tier1Item.children || null;
            }

            if (tier1Item.children) {
                const childItem = tier1Item.children.find((item) => {
                    return assertLoseNodeMatch(item, node);
                });

                if (childItem) {
                    return childItem.children || null;
                }
            }
        }

        return structure;
    };

    const childDepth = (node: ContentStructureNavItem): number => {
        let depth = 0;
        if (node?.children) {
            node.children.forEach(function (child) {
                let tmpDepth = childDepth(child);
                if (tmpDepth > depth) {
                    depth = tmpDepth;
                }
            });
        }
        return 1 + depth;
    };

    const getBranchLengthForNode = (node: ContentStructureNavItem) => {
        const paths = getPathsForNode(node);
        return childDepth(paths[0]);
    };

    const getPathsForNode = (node: ContentStructureNavItem) => {
        const paths: ContentStructureNavItem[] = [];

        for (let i = 0; i < structure.length; i++) {
            const tier1Item = structure[i];

            if (assertLoseNodeMatch(tier1Item, node)) {
                paths.push(tier1Item);
            }

            if (!tier1Item.children) continue;
            const { children: tier1Children } = tier1Item;

            for (let j = 0; j < tier1Children.length; j++) {
                const tier2Item = tier1Children[j];
                if (assertLoseNodeMatch(node, tier2Item)) {
                    paths.push(tier1Item, tier2Item);
                }

                if (!tier2Item.children) continue;
                const { children: tier2Children } = tier2Item;

                for (let k = 0; k < tier2Children.length; k++) {
                    const level3Item = tier2Children[k];
                    if (assertLoseNodeMatch(node, level3Item)) {
                        paths.push(tier1Item, tier2Item, level3Item);
                    }
                }
            }
        }

        return paths;
    };

    return {
        getNavFromNode,
        getPathsForNode,
        getBranchLengthForNode,
        assertLoseNodeMatch,
        structure,
    };
}
