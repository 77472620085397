import React from 'react';
import { Button } from 'mmds';
import useWishlist from '@app/lib/wishlist/state';
import { useParams, useRouter } from 'next/navigation';
import localisePath from '@app/lib/helpers/localise-path';
import CartWishlistLoginCta from '@app/[lang]/cart/cart-wishlist-login-cta/cart-wishlist-login-cta';
import { Language } from '@app/lib/store-context/Context';
import useUI from '@app/components/ui/state';
import s from './cart-sidebar-wishlist-footer.module.css';

const CartSidebarWishlistFooter = () => {
    const { clear, itemCount } = useWishlist();
    const { toggleUI } = useUI();
    const { push } = useRouter();
    const { lang } = useParams();
    return (
        <div className={s.root}>
            <CartWishlistLoginCta />
            <div className={s.summary}>
                <div className="mmds-copy-three-detail">{itemCount} Items</div>
                <div className={s.buttons}>
                    <Button
                        label="Clear All"
                        variant="ghost"
                        onClick={() => {
                            clear();
                            toggleUI('cart');
                        }}
                    />
                    <Button
                        label="View Wishlist"
                        onClick={() => {
                            push(
                                localisePath(
                                    '/cart?view=wishlist',
                                    lang as Language
                                )
                            );
                            toggleUI('wishlist');
                        }}
                        radiusSize="none"
                    />
                </div>
            </div>
        </div>
    );
};

export default CartSidebarWishlistFooter;
