import React from 'react';
import { CapsuleCard as MMDSCard, IconName } from 'mmds';
import s from './nav-card.module.css';
import { Language } from '@app/lib/store-context/Context';
import useUI from '@app/components/ui/state';
import Linker from '../linker/linker';

interface NavCardProps {
    image: string;
    ctaText: string;
    ctaLink: string;
    ctaIcon?: IconName;
    lang?: Language;
}

const NavCard = ({
    image,
    ctaText,
    ctaLink,
    ctaIcon,
    lang,
}: NavCardProps): JSX.Element | null => {
    const { toggleUI } = useUI();

    if (!image) return null;
    return (
        <section className={s.root}>
            <Linker
                href={ctaLink}
                title={ctaText}
                className={s.link}
                lang={lang as Language}
                onClick={() => {
                    toggleUI(null);
                }}
            >
                <div className={s.navCardOverlayOnImage} />
                <MMDSCard
                    imageSrc={image}
                    imageAlt=""
                    ctaText={ctaText}
                    ctaIcon={ctaIcon}
                />
            </Linker>
        </section>
    );
};

export default NavCard;
