import { Language } from '@app/lib/store-context/Context';

export default (lang: Language, currencyCode: string): string | null => {
    if (!Intl.NumberFormat) return null;
    const numberFormat = new Intl.NumberFormat(lang, {
        style: 'currency',
        currency: currencyCode,
        currencyDisplay: 'narrowSymbol',
    });
    const currency = numberFormat
        .formatToParts(1)
        .find((x) => x.type === 'currency')?.value;
    return currency || null;
};
