'use client';
import React from 'react';
import withDismissible from '@app/components/ui/dismissible';
import Link from 'next/link';
import { Close } from 'mmds';
import cn from 'classnames';
import s from './dismissible-announcement-ribbon.module.css';

interface AnnouncementItem {
    text: string;
    link?: string;
}

interface Announcement {
    backgroundColour?: string;
    textColour?: string;
    closeColour?: string;
    items: AnnouncementItem[];
}
interface RibbonProps {
    announcement: Announcement;
    countryCode: string;
    userCountryVercel?: string;
    customName?: string;
    setIsAnnouncementDismissed: (value: boolean) => void;
    /**
     * Is currently dismissed
     */
    dismissed: boolean;
    /**
     * Dismiss function from useDismissible
     */
    dismiss: (name: string) => void;

    /**
     * Receives a value while the component is transitioning in or out
     */
    transition: null | 'in' | 'out';
}

let name = 'Announcement Ribbon';

const DismissibleAnnouncementRibbon = ({
    announcement,
    countryCode,
    dismiss,
    dismissed,
    transition,
    userCountryVercel,
    customName,
    setIsAnnouncementDismissed,
    ...props
}: RibbonProps) => {
    const dismissalHandler = () => {
        setIsAnnouncementDismissed(true);
        dismiss(name);
    };

    const renderAnnouncementArray = () => {
        return announcement.items.map((item, index) => {
            const { text, link = '' } = item;
            if (!text) return;

            let href = link;
            if (link && link.indexOf('http') === -1) {
                href =
                    countryCode.toUpperCase() === 'AU'
                        ? link
                        : `/${countryCode.toLowerCase()}${link}`;
            }

            if (link) {
                return (
                    <span key={`announcementItem${index}`}>
                        <Link href={href} prefetch={false} className={s.link}>
                            {text}
                        </Link>
                        <span>{'>'}</span>
                    </span>
                );
            }

            return (
                <span key={`announcementItem${index}`} className={s.noLink}>
                    {text}
                </span>
            );
        });
    };

    if (
        !announcement?.items ||
        !Array.isArray(announcement.items) ||
        !announcement.items.length
    ) {
        return null;
    }

    return (
        <div
            role="navigation"
            className={cn(
                s.root,
                { [s.hidden]: dismissed },
                { [s.in]: transition == 'in' },
                { [s.out]: transition == 'out' }
            )}
            style={{
                backgroundColor: announcement.backgroundColour || '#222222',
                color: announcement.textColour || '#ffffff',
            }}
            data-testid={`ribbon-${name}`}
            {...props}
        >
            <div className={s.column}></div>
            <div className={s.column}>
                <div className={cn(s.content, 'mmds-component-one-detail')}>
                    {renderAnnouncementArray()}
                </div>
            </div>
            <div className={cn(s.column, s.end)}>
                <button
                    onClick={dismissalHandler}
                    className={s.button}
                    style={{
                        color: announcement.closeColour || '#ffffff',
                    }}
                >
                    <Close />
                </button>
            </div>
        </div>
    );
};

export default withDismissible(DismissibleAnnouncementRibbon, name);
