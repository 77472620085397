import { lazy } from 'react';
import { HeaderNavProps } from './types';
const HeaderNavDesktop = lazy(() => import('./header-nav-desktop'));
const HeaderNavMobile = lazy(() => import('./header-nav-mobile'));
import s from './header-nav.module.css';

const HeaderNav = (props: HeaderNavProps) => {
    const { callbacks } = props;
    return (
        <>
            <div className={s.mobileOnly}>
                <HeaderNavMobile callbacks={callbacks} lang={props.lang} />
            </div>
            <div className={s.desktopOnly}>
                <HeaderNavDesktop {...props} />
            </div>
        </>
    );
};

export default HeaderNav;
