'use client';
import Script from 'next/script';
import { create } from 'zustand';
import ImpactTracking from './lib/impact-tracking/impact-tracking';
import { Suspense } from 'react';
interface ScriptState {
    nostoInitialised: boolean;
    toggleNostoInitialised: () => void;
    setNostoInitialised: (value: boolean) => void;
}

export const useScript = create<ScriptState>((set) => ({
    nostoInitialised: false,
    hashedEmail: null,
    toggleNostoInitialised: () =>
        set((state) => ({ nostoInitialised: !state.nostoInitialised })),
    setNostoInitialised: (value: boolean) =>
        set(() => ({ nostoInitialised: value })),
}));

const Scripts = ({ config }) => {
    const { setNostoInitialised } = useScript();
    // load only nosto in development, all the other tracking
    // scripts slow things down and create console errors.
    if (process.env.NODE_ENV === 'development')
        return (
            <>
                {!!config?.nostoAccountID && (
                    <Script
                        strategy="afterInteractive"
                        id="nosto-script"
                        src={`https://connect.nosto.com/script/shopify/nosto.js?merchant=${config?.nostoAccountID}`}
                        onReady={() => {
                            if (
                                typeof (window as any).nostojs !== 'undefined'
                            ) {
                                (window as any).nostojs((api) =>
                                    api.setAutoLoad(false)
                                );
                                setNostoInitialised(true);
                                window.dispatchEvent(new Event('nosto:loaded'));
                            }
                        }}
                    />
                )}
            </>
        );

    return (
        <>
            <Script
                id="prismic-preview"
                strategy="afterInteractive"
                src="https://static.cdn.prismic.io/prismic.js?new=true&repo=maap-mmds"
            />
            {config.countryCode === 'US' && (
                <Suspense>
                    <ImpactTracking />
                </Suspense>
            )}
            {!!config.gtmContainerID && (
                <Script
                    id="gtm"
                    strategy="beforeInteractive"
                    dangerouslySetInnerHTML={{
                        __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document, 'script', 'dataLayer', '${config.gtmContainerID}');`,
                    }}
                />
            )}
            {process.env.NEXT_PUBLIC_HOTJAR_ID && (
                <Script
                    id="hotjar"
                    strategy="afterInteractive"
                    dangerouslySetInnerHTML={{
                        __html: `(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; h._hjSettings={hjid:${process.env.NEXT_PUBLIC_HOTJAR_ID},hjsv:6}; a=o.getElementsByTagName('head')[0]; r=o.createElement('script');r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
                    }}
                />
            )}
            {!!config?.nostoAccountID && (
                <Script
                    strategy="afterInteractive"
                    id="nosto-script"
                    src={`https://connect.nosto.com/script/shopify/nosto.js?merchant=${config?.nostoAccountID}`}
                    onReady={() => {
                        if (typeof (window as any).nostojs !== 'undefined') {
                            (window as any).nostojs((api) =>
                                api.setAutoLoad(false)
                            );
                            setNostoInitialised(true);
                        }
                    }}
                />
            )}
            {!!config.pinterestTagId && (
                <Script
                    src="https://s.pinimg.com/ct/core.js"
                    id="pintrk-scripts"
                    strategy="afterInteractive"
                    onLoad={() => {
                        if (!(window as any).pintrk) {
                            (window as any).pintrk = function () {
                                (window as any).pintrk.queue.push(
                                    Array.prototype.slice.call(arguments)
                                );
                            };
                            let n = (window as any).pintrk;
                            (n.queue = []), (n.version = '3.0');
                        }
                        (window as any).pintrk('load', config.pinterestTagId, {
                            em: config.pinterestContactEmail,
                        }); //
                        (window as any).pintrk('page');
                    }}
                />
            )}
            {!!config.klaviyoCompanyID && (
                <Script
                    strategy="afterInteractive"
                    src={`https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${config.klaviyoCompanyID}`}
                    onLoad={() => {
                        if (
                            (window as any) !== undefined &&
                            (window as any)._learnq === undefined
                        ) {
                            (window as any)._learnq = [];
                        }
                    }}
                />
            )}
            <Script
                id={'osano-script'}
                strategy="afterInteractive"
                src={`https://cmp.osano.com/AzZVb9THSVMwP4LtK/7cee66db-8e74-4787-90b8-7d1ff79ce58e/osano.js`}
                onLoad={() => {
                    (window as any).Osano.cm.addEventListener(
                        'osano-cm-consent-saved',
                        (data) => {
                            (window as any).cookie_consent = data;
                        }
                    );
                }}
            />
            {!!config.metaPixelID && (
                <>
                    <Script
                        id="meta-pixel"
                        strategy="afterInteractive"
                        dangerouslySetInnerHTML={{
                            __html: ` !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)}; if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0'; n.queue=[];t=b.createElement(e);t.async=!0; t.src=v;s=b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t,s)}(window, document,'script', 'https://connect.facebook.net/en_US/fbevents.js'); fbq('init', ${config.metaPixelID}); fbq('track', 'PageView');`,
                        }}
                    />
                    {/* <noscript>
                        <img
                            height="1"
                            width="1"
                            style={{ display: 'none' }}
                            src={`https://www.facebook.com/tr?id=${config.metaPixelID}&ev=PageView&noscript=1`}
                            alt=""
                        />
                    </noscript> */}
                </>
            )}
        </>
    );
};

export default Scripts;
