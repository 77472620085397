'use client';

import React, { useEffect, useState } from 'react';
import useUI from '@app/components/ui/state';
import {
    Drawer,
    DrawerHeader,
    DrawerPanel,
} from '@app/components/ui/drawer/Drawer';
import { useRouter } from 'next/navigation';
import { useParams } from 'next/navigation';
import { usePathname } from 'next/dist/client/components/navigation';
import useDrawerDirection from '@app/lib/hooks/use-drawer-direction';
import { LanguageNavItem } from '@app/components/language-switch/methods/get-language-nav-items';
import LangFlag from '@app/components/lang-flag/lang-flag';
import setPseudoLang from '@app/lib/layout/set-pseudo-lang';
import { Language } from '@app/lib/store-context/Context';
import { PatternCTASet } from 'mmds';
import getPseudoLang from '@app/lib/layout/get-pseudo-lang';
import cn from 'clsx';
import s from './language-switch.module.css';

const LanguageSwitch = ({ languageNavItems }) => {
    const { push } = useRouter();
    const { lang } = useParams();
    const pathname = usePathname();
    const drawerDirection = useDrawerDirection('bottom', 'left');
    const { openUIKey, toggleUI } = useUI();
    const pseudoLang = getPseudoLang();
    const [activeRegion, setActiveRegion] = useState<null | LanguageNavItem>(
        null
    );
    const [selectedNavItem, setSelectedNavItem] =
        useState<null | LanguageNavItem>(null);

    useEffect(() => {
        let activeRegion = languageNavItems.find(
            (item: LanguageNavItem) => item.language === lang
        );
        if (pseudoLang) {
            activeRegion = Object.assign({}, activeRegion);
            activeRegion.label = 'Rest of the World';
        }
        setActiveRegion(activeRegion);
    }, []);

    const updateLanguage = (targetRegion: LanguageNavItem) => {
        // switch region custom GA4 event
        if (typeof window !== 'undefined') {
            (window as any).dataLayer?.push({
                event: 'gtm.switchRegion',
                switchRegionDetails: {
                    currentRegion: lang,
                    selectedTargetRegion: targetRegion.language,
                },
            });
        }
        toggleUI(null);
        setSelectedNavItem(null);

        // MAAP have a specific independent partner website for Korea, we just send the browser there for this
        // use-case.
        if (targetRegion.label === 'Korea') {
            window.location.assign('https://maap.kr');
            return;
        }

        // MAAP have a thing where they use AU as "Rest of World" but "RoW" is not a real region in anything other
        // than showing a different flag.
        // Therefore we have to save a Cookie which tells us to use the alternative flag.
        if (targetRegion.label === 'Rest of the World') {
            setPseudoLang('en-ROW');
        } else {
            setPseudoLang(null);
        }

        const targetPath: null | string = pathname?.replace(
            `/${activePrefix}`,
            ''
        );
        const newPrefix = targetRegion.countryCode.toLowerCase();
        if (!targetPath) window.location.href = `/${newPrefix}`;
        else window.location.href = `/${newPrefix}/${targetPath}`;
    };

    if (!activeRegion) return null;

    const activePrefix = (lang as string)?.replace(/en-/g, '').toLowerCase();
    const isDrawerOpen = openUIKey === 'language-switch';
    const willSwitch =
        selectedNavItem && selectedNavItem.label !== activeRegion?.label;

    const sourceRegionCodeWithPseudo =
        activeRegion?.label === 'Rest of the World'
            ? 'RoW'
            : activeRegion?.countryCode;

    let targetRegionCodeWithPseudo: null | string = null;
    if (selectedNavItem) {
        targetRegionCodeWithPseudo =
            selectedNavItem.label === 'Rest of the World'
                ? 'RoW'
                : selectedNavItem.countryCode;
    }

    return (
        <Drawer
            open={isDrawerOpen}
            onOpenChange={() => {
                toggleUI('language-switch');
                setSelectedNavItem(null);
            }}
        >
            <DrawerPanel inFrom={drawerDirection} variant="lookbook">
                <DrawerHeader
                    className="mmds-component-one"
                    closeButtonVariant="text"
                >
                    Select your location
                </DrawerHeader>
                <div className={s.root}>
                    {selectedNavItem && willSwitch && (
                        <div className={s.notice}>
                            <h4 className={`${s.noticeTitle} mmds-copy-one`}>
                                Are you sure you want to switch?
                            </h4>
                            <p
                                className={`${s.noticeText} mmds-copy-one-serif`}
                            >
                                Applying the selected changes with switch to
                                your {targetRegionCodeWithPseudo} cart, any
                                items in your current cart will be cleared.
                            </p>
                            <PatternCTASet
                                set={[
                                    {
                                        onClick: () =>
                                            updateLanguage(selectedNavItem),
                                        label: `Switch from ${sourceRegionCodeWithPseudo} > ${targetRegionCodeWithPseudo} / English `,
                                        icon: 'globe',
                                    },
                                    {
                                        onClick: () => setSelectedNavItem(null),
                                        label: 'Cancel',
                                        variant: 'ghost',
                                    },
                                ]}
                                lang={lang as Language}
                            />
                            <hr className={s.divider} />
                        </div>
                    )}
                    {!willSwitch && (
                        <div>
                            {languageNavItems.map(
                                (languageNavItem: LanguageNavItem) => {
                                    const {
                                        label,
                                        countryCode,
                                        currency,
                                        currencySymbol,
                                        language,
                                    } = languageNavItem;
                                    let selected =
                                        label === activeRegion?.label;
                                    if (selectedNavItem) {
                                        selected =
                                            label === selectedNavItem?.label;
                                    }
                                    const languageWithPseudoLang =
                                        label === 'Rest of the World'
                                            ? 'en-ROW'
                                            : language;
                                    return (
                                        <div
                                            key={languageNavItem.label}
                                            className={s.navItem}
                                        >
                                            <h4
                                                className={`${s.navItemTitle} mmds-copy-one`}
                                            >
                                                {label}
                                            </h4>
                                            <button
                                                onClick={() => {
                                                    setSelectedNavItem(
                                                        languageNavItem
                                                    );
                                                }}
                                                className={cn(s.button, {
                                                    ['mmds-component-one-detail']:
                                                        !selected,
                                                })}
                                            >
                                                <LangFlag
                                                    lang={
                                                        languageWithPseudoLang as Language
                                                    }
                                                    className={s.flag}
                                                />{' '}
                                                <span
                                                    className={cn({
                                                        ['mmds-component-one-detail-link']:
                                                            selected,
                                                    })}
                                                >
                                                    {countryCode} / {currency}{' '}
                                                    {currencySymbol}
                                                </span>
                                            </button>
                                            <hr className={s.divider} />
                                        </div>
                                    );
                                }
                            )}
                        </div>
                    )}
                </div>
            </DrawerPanel>
        </Drawer>
    );
};

export default LanguageSwitch;
