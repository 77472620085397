import formatComponentName from '@app/components/ui/utils/format-component-name';

export default function getIsDismissedCookieValue(componentName: string) {
    if (typeof document === 'undefined') return null;
    const cookies = document.cookie.split(';');
    const cookie = cookies.find((cookie) =>
        cookie.trim().startsWith(formatComponentName(componentName))
    );
    if (!cookie) return false;
    const value = cookie.split('=')[1];
    return value === 'true';
}
