import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { hashEmail } from '../helpers/hash-email';
import { v4 as generateSessionUUID } from 'uuid';
import fetchProfile from '@app/lib/profile/fetch-profile';

// This is called profile not wishlist because it will...
// ... later contain the rest of the profile state.
export interface Profile {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    region: string;
    tier: string;
    hashedEmail: string;
}

export enum AuthStatus {
    UNKNOWN = 'UNKNOWN',
    CHECKING = 'CHECKING',
    AUTHENTICATED = 'AUTHENTICATED',
    UNAUTHENTICATED = 'UNAUTHENTICATED',
}

export interface ProfileState {
    authStatus: AuthStatus;
    error: string | null;
    sessionUUID: string; // A unique session identifier use for stitching sessions between guest/user states.
    profile: Profile | null;
    authToken: string | null;
    setAuthToken: (token: string) => void;
    setAuthStatus: (authStatus: AuthStatus) => void;
    setProfile: (profile: Profile | null) => void;
    checkUserSession: () => void;
    clearProfile: () => void;
}

export const useProfile = create<ProfileState>()(
    persist(
        (set, get) => ({
            authStatus: AuthStatus.UNKNOWN,
            sessionUUID: generateSessionUUID(),
            profile: null,
            authToken: null,
            error: null,

            setAuthToken: (token: string) => set({ authToken: token }),

            setAuthStatus: (status: AuthStatus) => set({ authStatus: status }),

            setProfile: (profile: Profile | null) => set({ profile: profile }),

            checkUserSession: async () => {
                const { profile, authToken } = get();
                if (profile) {
                    set({ authStatus: AuthStatus.AUTHENTICATED });
                    return;
                }

                if (!authToken) {
                    set({ authStatus: AuthStatus.UNAUTHENTICATED });
                    return;
                }

                try {
                    set({ authStatus: AuthStatus.CHECKING });

                    const profile = await fetchProfile(
                        get().authToken as string
                    );

                    if (profile) {
                        const {
                            id,
                            email,
                            first_name,
                            last_name,
                            region,
                            tier,
                        } = profile.data;

                        const hashedEmail = await hashEmail(email);

                        set({
                            profile: {
                                id: id,
                                email: email,
                                firstName: first_name,
                                lastName: last_name,
                                region: region,
                                tier: tier,
                                hashedEmail,
                            },
                            authStatus: AuthStatus.AUTHENTICATED,
                        });
                    } else {
                        throw new Error('Profile not found');
                    }
                } catch (error) {
                    set({
                        error: error.message,
                        authStatus: AuthStatus.UNAUTHENTICATED,
                    });
                }
            },

            clearProfile: () => {
                useProfile.persist.clearStorage();
                set({
                    sessionUUID: generateSessionUUID(),
                    profile: null,
                    authToken: null,
                });
                get().checkUserSession();
            },
        }),
        {
            name: 'profile:cache',
            storage: createJSONStorage(() => localStorage), // This could also be indexedDB or sessionStorage or....
            partialize: (state) => ({
                profile: state.profile,
                authToken: state.authToken,
            }), // Adjust when do offline wishlist?
        }
    )
);

export default useProfile;
