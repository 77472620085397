// V1 - Use this simple function to toggle functions using environment variables.
// This allows you to show features on different environments for testing e.g.
// wishlist in development but not in production.
// Later we may expand this to integrate something like hypertune and add AB testing.

// Unfortunately, Next Public variables don't support dynamic inlining
// e.g. process.env[environmentFlagKey], so we need to define a matrix
// of features to pick from with fully qualified env var names.
// https://nextjs.org/docs/pages/building-your-application/configuring/environment-variables#bundling-environment-variables-for-the-browser

const featureMatrix = {
    wishlist: process.env.NEXT_PUBLIC_FEATURE_WISHLIST,
    imageProxy: process.env.NEXT_PUBLIC_FEATURE_IMAGE_PROXY,
};

export default function getFeatureFlag(
    featureKey: string,
    fallback: boolean = false
) {
    const featureFlag = featureMatrix[featureKey];
    if (typeof featureFlag === 'undefined' || featureFlag.length === 0) {
        return fallback;
    }
    if (featureFlag === 'true') {
        return true;
    }
    return false;
}
