export async function hashEmail(email: string | null): Promise<string> {
    if (!email) throw new Error('Email is required for hashing.');
    const encoder = new TextEncoder();
    const data = encoder.encode(email);

    const hashBuffer = await crypto.subtle.digest('SHA-1', data);

    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
        .map((b) => b.toString(16).padStart(2, '0'))
        .join('');

    return hashHex;
}
